import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DxHtmlEditorComponent } from 'devextreme-angular';

export interface EidosAngularEditorConfig extends AngularEditorConfig {
  multilineToolbar?: boolean;
  height: 'auto' | string;
}

@Component({
  selector: 'eidos-input-editor',
  templateUrl: './eidos-input-editor.component.html',
  styleUrls: ['./eidos-input-editor.component.scss']
})
export class EidosInputEditorComponent implements AfterViewInit {


  @Input() type: 'angularEditor' | 'dxHtmlEditor' = 'angularEditor';

  // common
  @Input() disabled: boolean = false;
  @Input() value: any | any[] = '';
  @Input() config: EidosAngularEditorConfig = { height: 'auto' };

  @Output() valueChange = new EventEmitter<any>();

  @ViewChild(DxHtmlEditorComponent, { static: false }) dxEditor?: DxHtmlEditorComponent;

  exclededAttributes = ['color','backgroudColor']
  ngOnInit() {
  }
  ngAfterViewInit(): void {
    if(this.type==='dxHtmlEditor') this.addMatcher()
  }
  addMatcher() {
    if(!this.dxEditor) return;

    this.dxEditor.instance.getModule('clipboard').addMatcher(Node.ELEMENT_NODE, (_node:any, delta:any) => {
      delta.ops = delta.ops.map((op:any) => {
        const attrs = op.attributes ? Object.entries(op.attributes).filter(([name, _value]) => !this.exclededAttributes.includes(name.toLowerCase())) : undefined
        return {
          attributes: attrs,
          insert: op.insert
        }
      })
      console.log('dopo',delta)
      return delta
    })

  }
}

import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';

import { EidosConfigService } from '../config/eidos-config.service';
import { IEidosFormats } from '../config/environment.interface';

@Injectable({
  providedIn: 'root'
})
export class CoreFormatService {
  /**
   * Default formats config
   *
   * @type {IEidosFormats}
   * @memberof CoreFormatService
   */
  private readonly DEFAULT_FORMATS: IEidosFormats = {
    defaultCurrency: 'USD',
    dateAndTime: 'dd/MM/yyyy HH:mm',
    dateFmt: 'dd/MM/yyyy',
    dateAndMonth: 'dd MMM',
    dateAndMonthAndYear: 'dd MMM yyyy',
    dateAndMonthAndYearAndTime: 'dd MMM yyyy HH:mm',
    dateQueryParams: 'yyyy-MM-dd', // Format used for query date params
    dateQueryApiFormat: 'yyyy-MM-dd HH:mm:ss', // Format used for API date params
    dateStandardLocaleOpts: { locale: 'en' },
    amountStandardFormatOpts: '1.2-2',
    amountStandardLocaleOpts: 'en',

    currencies: {
      USD: "$",
      EUR: "€",
      GBP: "£",
      AUD: "A$",
      CAD: "C$",
    },
  }
  /**
   * Current formats config
   *
   * @type {BehaviorSubject<IEidosFormats>}
   * @memberof CoreFormatService
   */
  private formats: BehaviorSubject<IEidosFormats> = new BehaviorSubject<IEidosFormats>(this.DEFAULT_FORMATS);
  /**
   * Internal decimal pipe
   *
   * @type {DecimalPipe}
   * @memberof CoreFormatService
   */
  private decimalPipe: DecimalPipe = new DecimalPipe('en');

  constructor(protected coreConfigService: EidosConfigService) {
    this.coreConfigService.currentConfig.subscribe(config => {
      this.formats.next(config.formats);
    });
  }

  // #region Private methods

  private getFromConfig(key: string): any {
    const formats = this.formats.getValue();
    return !!formats.hasOwnProperty(key) ? (<any>formats)[key] : undefined;
  }

  // #endregion Private methods

  // #region Public instance methods

  /**
   * Customize some configuration formats
   * Entries of parameter object must exists as config format properties
   *
   * @param {{ [key: string]: any }} customizations
   * @memberof CoreFormatService
   */
  setCustomFormats(customizations: { [key: string]: any }) {
    this.formats.next(_.merge(this.formats.getValue(), customizations));
  }

  defaultCurrency(): string {
    return this.getFromConfig('defaultCurrency');
  }

  DateAndTimeFmt(): string {
    return this.getFromConfig('dateAndTime');
  }

  DateFmtBase(): string {
    return this.getFromConfig('dateFmt');
  }

  DateFmtWithMonthNoYear(): string {
    return this.getFromConfig('dateAndMonth');
  }

  DateFmtWithMonthName(): string {
    return this.getFromConfig('dateAndMonthAndYear');
  }

  DateFmtWithMonthNameAndTime(): string {
    return this.getFromConfig('dateAndMonthAndYearAndTime');
  }

  DateFmtWithMonthNameYear2Digits(): string {
    return this.getFromConfig('dateAndMonthAndYear2Digits');
  }

  DateQueryString(): string {
    return this.getFromConfig('dateQueryParams');
  }

  DateQueryApiFormat(): string {
    return this.getFromConfig('dateQueryApiFormat');
  }

  GetDateStandardLocaleOpts(): { locale: string } {
    return this.getFromConfig('dateStandardLocaleOpts');
  }

  GetAmountStandardFormatOpts(): string {
    return this.getFromConfig('amountStandardFormatOpts') ?? this.DEFAULT_FORMATS.amountStandardFormatOpts;
  }

  GetAmountStandardLocaleOpts(): string {
    return this.getFromConfig('amountStandardLocaleOpts') ?? this.DEFAULT_FORMATS.amountStandardLocaleOpts;
  }

  getCurrencySymbol(currency?: string): string {
    if (!currency) return this.getFromConfig('currencies')[this.defaultCurrency()];
    return this.getFromConfig('currencies')[currency.trim().toUpperCase()];
  }

  /**
   * Format an amount in a currency
   * ES 12.23 $ / $ 12.23
   *
   * @param {number} amount
   * @param {string} [currency] Currency code
   * @param {string} [format] {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
   * @param {boolean} [inverted=false]
   * @return {*}  {string}
   * @memberof CoreFormatService
   */
  CurrencyAmount(amount: number, currency?: string, format?: string, inverted: boolean = false): string {
    const currencySymbol = this.getCurrencySymbol(currency) || currency;
    const formattedAmount = this.decimalPipe.transform(amount, (!!format ? format : this.GetAmountStandardFormatOpts()), this.GetAmountStandardLocaleOpts());
    return inverted ? `${formattedAmount} ${currencySymbol}` : `${currencySymbol} ${formattedAmount}`;
  }

  /**
   * Format an amount in a currency (inverted)
   * ES $ 12.23
   *
   * @param {number} amount
   * @param {string} currency
   * @param {string} [format] Custom number format
   * @return {*}  {string}
   * @memberof CoreFormatService
   */
  CurrencyAmountInverted(amount: number, currency: string, format?: string): string {
    return this.CurrencyAmount(amount, currency, format, true);
  }

  /**
   * Format a date in default behavier
   * ES 15 Jul 2022
   *
   * @param {Date} date
   * @return {*}  {string}
   * @memberof CoreFormatService
   */
  JsDateToDefaultDateFormat(date: Date): string {
    const dt = DateTime.fromJSDate(date);
    if (!dt.isValid) return '';
    return dt.toFormat(this.DateFmtWithMonthName());
  }

  /**
   * Format a date in default behavier
   * ES 15 Jul 2022
   *
   * @param {DateTime} date
   * @return {*}  {string}
   * @memberof CoreFormatService
   */
  DateToDefaultDateFormat(date: DateTime): string {
    if (!date.isValid) return '';
    return date.toFormat(this.DateFmtWithMonthName());
  }
  /**
   * Format a number to percent
   * Es 12.23%
   *
   * @param {number} value
   * @param {string} [format] Custom number format
   * @return {*}  {string}
   * @memberof CoreFormatService
   */
  PercentualNumber(value: number, format?: string): string {
    const formattedValue = this.decimalPipe.transform(value, (!!format ? format : '1.2-2'), 'en');
    return `${formattedValue}%`;
  }

  DateFmtOptsDayNameMonthStartYearDay(): DateTimeFormatOptions {
    return { weekday: 'long', day: '2-digit', month: 'short', year: '2-digit' };
  };

  TimeFmtOptsHourMinuteAMPM(): DateTimeFormatOptions {
    return DateTime.TIME_SIMPLE;
  }

  deeplyConvertDateTimeOfObject(data: any) {

    const DeeplyConvertDateTimeOfObjectProp = (prop: any): any => {

      if (prop !== undefined && prop !== null) {

        if (Array.isArray(prop)) {
          return prop.map(item => DeeplyConvertDateTimeOfObjectProp(item));
        }

        if (prop instanceof DateTime && prop.isValid) {
          return prop.isValid ? prop.toFormat(this.DateQueryApiFormat()) : '';
        }

        if (prop instanceof Date) {
          const offset = prop.getTimezoneOffset();
          const date = new Date(prop.getTime() - (offset * 60 * 1000));
          try {
            return date.toISOString().split('T')[0] + ' ' + date.toISOString().split('T')[1].split('.')[0];
          } catch (e: unknown) {
            return '';
          }
        }

        if (typeof prop === 'object') {

          Object.keys(prop).forEach(key => {
            prop[key] = DeeplyConvertDateTimeOfObjectProp(prop[key]);
          });

          return prop;
        }
      }

      return prop;
    }

    return DeeplyConvertDateTimeOfObjectProp(data);
  }

  // #endregion Public instance methods

  // #region Public static methods

  static FormatDateToApi(date: DateTime | undefined): string | undefined {
    return date != undefined && date.isValid ? date.toISODate() : undefined;
  }

  static ApiFormatToDate(date: string): DateTime | undefined {
    if (date !== undefined) {
      const dateTime = DateTime.fromISO(date);
      if (dateTime.isValid) {
        return dateTime;
      }
    }
    return undefined;
  }

  static FormatTimeToApi(date: DateTime | undefined): string | undefined {
    return date !== undefined && date.isValid ? date.toISOTime({ includeOffset: false }) : undefined;
  }
  /**
   * Check if a string is a valid email
   *
   * @static
   * @param {string} val
   * @return {*}  {boolean}
   * @memberof CoreFormatService
   */
  static IsEmail(val: string): boolean {
    // TODO: to review, it accepts mails like "sswedman@crystalcruise" ???
    return !!val && !!val.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$");
  }

  /**
   * Metodo per settare le date con il fuso orario
   * Per usarlo inserire un valore di tipo DateTime oppure string,
   * settare il setTimeZone su true se vuoi convertire la data con il fuso orario dello user
   * oppure inserisci false se vuoi convertire una data in UTC.
   * Inserisci il formato se vuoi il risultato diverso da 'yyyy/MM/dd HH:mm'
   *
   * @param {*} value - DateTime | string | null | undefined
   * @param {*} setTimeZone - boolean
   * @param {*} format - string
   *
   * @return {*} string | null
   * @memberof CoreFormatService
   */
  static setTimeZone(value: Date | DateTime | string | null | undefined, timezone: number, setTimeZone: boolean = false, format: string = 'yyyy/MM/dd HH:mm'): any {

    let date: DateTime;
    if (!_.isUndefined(value) && !_.isNull(value)) {
      if (typeof value === 'string') {
        try {
          date = DateTime.fromJSDate(new Date(value));
        } catch {
          return null;
        }
      } else {
        date = DateTime.fromJSDate(<Date>value);
      }

      if (setTimeZone == true) {
        return date.plus({ hours: timezone }).toFormat(format);
      } else {
        return date.setZone('utc').toFormat(format);
      }
    } else {
      return null;
    }
  }

  // #endregion Public static methods
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ResCurrency } from '@app/reservation/models/res-owner.model';
import { CoreUtilityDialog, EidosUtilityService } from '@app/core/services/eidos-utility.service';
import { EidosModalService } from '@app/core/components/eidos-modal/eidos-modal.service';
import { DateTime } from 'luxon';
import { ReservationPermission } from '../models/reservation-permission.model';
import { IEidosEnvironment } from '@app/core/config/environment.interface';
import { EidosConfigService } from '@app/core/config/eidos-config.service';


@Injectable({
  providedIn: 'root'
})
export class RescomService {

  utilityDialogs: CoreUtilityDialog;

  onSelectedVoyageChange: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private eidosModalService: EidosModalService,
    private coreUtilityService: EidosUtilityService,
    private eidosConfigService: EidosConfigService
  ) {
    this.utilityDialogs = new CoreUtilityDialog(this.eidosModalService);
    this.config = this.eidosConfigService.currentConfig.getValue();
  }

  public getAvailableCompanies() {
    return this.coreUtilityService.getAvailableCompanies();
  }

  allValidCurrencies: BehaviorSubject<Array<ResCurrency>> = new BehaviorSubject<Array<ResCurrency>>([]);

  currentReservationPermission: BehaviorSubject<ReservationPermission> = new BehaviorSubject<ReservationPermission>(ReservationPermission.emptyPermission());

  companyDataReload = new BehaviorSubject<boolean>(false);

  protected config: IEidosEnvironment | undefined;

  public static buildDateRange(d1: DateTime, d2?: DateTime) {
    if (!d1 && !d2) return [];
    if (!d2) return [d1];
    let days = d2.diff(d1, 'days').days;
    let dateRange = [];
    for (let i = 0; i <= days; i++) {
      dateRange.push(d1.plus({ days: i }));
    }
    return dateRange;
  }

  public static getNumberFromString(str: string): number {
    const number = Number(str.replace(/[^0-9.-]+/g, ''))
    return !isNaN(number) ? number : 0;
  }

  public downBlobFileGeoTree(guid: string) {
    return `${this.config?.urlAPI}/documents/document-blob-guid?FileGuid=${guid}`;
  }

}

<div [ngSwitch]="type">

    <div *ngSwitchCase="'angularEditor'">
        <angular-editor [(ngModel)]="value" [config]="config" (ngModelChange)="valueChange.emit($event)" [disabled]="disabled"></angular-editor>
    </div>

    <div *ngSwitchCase="'dxHtmlEditor'">
        <dx-html-editor style="background-color: white;" [height]="config.height" [(value)]="value" (valueChange)="valueChange.emit($event)" [disabled]="disabled">
            <dxo-toolbar [multiline]="config.multilineToolbar">
                <dxi-item name="undo"></dxi-item>
                <dxi-item name="redo"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item
                name="size"
                [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"
                [options]="{ inputAttr: { 'aria-label': 'Font size' } }"
                ></dxi-item>
                <dxi-item
                name="font"
                [acceptedValues]="[
                    'Arial',
                    'Tahoma',
                    'Times New Roman',
                    'Verdana'
                ]"
                [options]="{ inputAttr: { 'aria-label': 'Font family' } }"
                ></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="bold"></dxi-item>
                <dxi-item name="italic"></dxi-item>
                <dxi-item name="strike"></dxi-item>
                <dxi-item name="underline"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="alignLeft"></dxi-item>
                <dxi-item name="alignCenter"></dxi-item>
                <dxi-item name="alignRight"></dxi-item>
                <dxi-item name="alignJustify"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="orderedList"></dxi-item>
                <dxi-item name="bulletList"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="link"></dxi-item>
                <dxi-item name="clear"></dxi-item>
                <dxi-item name="separator"></dxi-item>
            </dxo-toolbar>
        </dx-html-editor>
    </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { EidosConfigService } from '@app/core/config/eidos-config.service';
import { IEidosModuleConfiguration } from '@app/core/config/environment.interface';
import { CoreModuleName } from '@app/core/models/core-constant.model';
import { DynamicApiResponse } from '@app/core/models/dynamic-api-response.model';
import { CoreFormatService } from '@app/core/services/core-format.service';
import { EidosBaseApiService } from '@app/core/services/eidos-base-api.service';
import { EidosLogService } from '@app/core/services/eidos-log.service';
import { EidosSecurityService } from '@app/core/services/eidos-security.service';
import { IReservationAirport, IReservationServiceType } from '@app/reservation/models/res-cached-data.models';
import { LoadOptions } from 'devextreme/data';
import { ResolvedData } from 'devextreme/data/custom_store';
import { Observable, filter, lastValueFrom, map } from 'rxjs';
import { IReservationApiGetServices, IReservationApiService, IReservationApiServiceType, IReservationApiSetupService, IReservationGeotreeCityPicklist, ISupplierDetailsAddress, ISupplierDetailsContact, ReservationServiceData, ReservationServiceType, ReservationSetupService, SupplierAddressType, SupplierContactType, SupplierDetails } from '../models/rescom-service.model';
import { IReservationApiGetSuppliers, IReservationModuleAPIConfiguration, ResReceiptType, ResVillaDetails } from '../models/rescom.model';
import { IReservationApiSupplier, ReservationSupplier } from '@app/res-setup/models/resadm-service.models';

@Injectable({
  providedIn: 'root'
})
export class RescomApiService extends EidosBaseApiService {

  constructor(
    public configService: EidosConfigService,
    public eidosSecurityService: EidosSecurityService,
    public http: HttpClient,
    public eidosLogService: EidosLogService,
    public dialog: MatDialog,
    public domSanitizer: DomSanitizer,
    public coreFormatService: CoreFormatService,
  ) {
    super(coreFormatService, configService, eidosLogService, dialog, eidosSecurityService, http);

    this.configService.currentModulesConfig
      .pipe(
        filter(
          (modules: Array<IEidosModuleConfiguration>) => !!modules.find((module) => module.moduleName === CoreModuleName.Reservation)
        )
      )
      .subscribe((modules) => {
        const module = modules.find((module) => module.moduleName === CoreModuleName.Reservation);

        if (module) {
          const reservationModuleAPI = module.API as IReservationModuleAPIConfiguration;
          this.urlAPI = reservationModuleAPI.url;
          this.staticUrlAPI = reservationModuleAPI.staticUrl;
          this.pluginUrlAPI = reservationModuleAPI.pluginUrl;
          this.apiVersion = reservationModuleAPI.version;
          if (reservationModuleAPI.headers) {
            this.headers = {
              ...reservationModuleAPI.headers,
              ...this.headers
            };
          }
        }
      });
  }

  private titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substring(1);
  }

  private localSort(items: [], loadOptions: any): [] {
    return items.sort((i1, i2) => {
      let field = loadOptions.sort[0].selector;
      let desc = loadOptions.sort[0].desc;
      let item1 = i1[field];
      if (!item1) {
        item1 = i1[this.titleCaseWord(field)];
      }
      let item2 = i2[field];
      if (!item2) {
        item2 = i2[this.titleCaseWord(field)];
      }
      if (item1 > item2) {
        return desc ? -1 : 1;
      }
      if (item1 < item2) {
        return desc ? 1 : -1;
      }
      return 0;
    });
  }

  private fillPaginatedParams(params?: any, loadOptions?: LoadOptions) {
    if (!params) params = {};

    if (!loadOptions) return params;

    const skip = loadOptions.skip ?? 0;
    const take = loadOptions.take ?? 20;

    params.PageNum = Math.floor(skip / take) + 1;
    params.PageSize = take;

    if (!!loadOptions.filter) {
      if (Array.isArray(loadOptions.filter[0])) {
        loadOptions.filter.forEach((f: any) => {
          if (Array.isArray(f)) {
            let field: string = f[0];
            let cmp: string = f[2];
            params[field] = cmp;
          }
        });
      } else {
        let field: string = loadOptions.filter[0];
        let cmp: string = loadOptions.filter[2];
        params[field] = cmp;
      }
    }

    if (!!loadOptions.sort) {
      const sort = loadOptions.sort as { selector: string; desc: boolean }[];

      params.OrderField = sort[0]?.selector;
      params.OrderDirect = sort[0]?.desc ? 1 : 0;
    }

    return params;
  }

  getSuppliers(params?: IReservationApiGetSuppliers): Observable<ReservationSupplier[]> {
    return this.callDynamicAPI('serviceSupplier/select', params).pipe(
      map<DynamicApiResponse, ReservationSupplier[]>((response) => response.body.map((s: IReservationApiSupplier) => new ReservationSupplier(s)))
    );
  }

  getVilla(villaID: number): Observable<ResVillaDetails> {
    return this.getDynamicAPI('villa/getVilla', { VillaID: villaID }).pipe(
      map<DynamicApiResponse, ResVillaDetails>((response) => {
        return new ResVillaDetails(response.body)
      })
    );
  }

  public getService(serviceID: number, companyOvewrite?: number): Observable<ReservationSetupService> {
    return this.callDynamicAPI('service/select', { serviceID: serviceID, companyID_OVERWRITE: companyOvewrite }).pipe(
      map<DynamicApiResponse, ReservationSetupService>((response) => {
        return new ReservationSetupService(response.body[0]);
      })
    );
  }

  public getServicesPaginated(params?: any, loadOptions?: any): Promise<ResolvedData<ReservationSetupService>> {
    params = this.fillPaginatedParams(params, loadOptions);

    return lastValueFrom(this.callDynamicAPI('service/select', params)).then((response: any) => {
      return {
        data: this.localSort(response.body, loadOptions).map((o: IReservationApiSetupService) => new ReservationSetupService(o)),
        totalCount: response.recordsCount
      };
    }).catch(() => {
      throw 'Data loading error'
    });
  }

  public manageService(params?: ReservationSetupService): Observable<any> {
    return this.postDynamicAPI('service/manage', params);
  }

  public getServiceTypes(filters?: IReservationApiServiceType): Observable<ReservationServiceType[]> {
    return this.callDynamicAPI('serviceType/select', filters).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body.map((c: IReservationServiceType) => new ReservationServiceType(c));
      })
    );
  }

  public getPicklistCitiesByID(id: number, departmentId = 1): Observable<IReservationGeotreeCityPicklist[]> {
    return this.callDynamicAPI('geotree/picklist', { GeoTreeID: id, departmentId: departmentId }).pipe(
      map<DynamicApiResponse, IReservationGeotreeCityPicklist[]>((response) => {
        return response.body
      })
    )
  }

  public getPicklistCities(city: string, departmentId = 1): Observable<IReservationGeotreeCityPicklist[]> {
    return this.callDynamicAPI('geotree/picklist', { GeoTreeName: city, DepartmentID: departmentId }).pipe(
      map<DynamicApiResponse, IReservationGeotreeCityPicklist[]>((response) => {
        return response.body
      })
    )
  }

  public getAirports(params: { AirportID?: number, AirName?: string }): Observable<Array<IReservationAirport>> {
    return this.callDynamicAPI('airport/select', params).pipe(
      map<DynamicApiResponse, Array<IReservationAirport>>((response) => {
        return response.body;
      })
    );
  }

  public getServices(params?: IReservationApiGetServices): Observable<ReservationServiceData[]> {
    return this.callDynamicAPI('service/select', params).pipe(
      map<DynamicApiResponse, Array<ReservationServiceData>>((response) => {
        return response.body.map((s: IReservationApiService) => new ReservationServiceData(s));
      })
    );
  }

  public manageSupplier(params?: ReservationServiceData): Observable<any> {
    return this.postDynamicAPI('serviceSupplier/manage', params).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body
      })
    )
  }

  public getSupplierDetails(supplierID: number): Observable<SupplierDetails> {
    return this.callDynamicAPI('supplier/getSupplierDetails', {SupplierID: supplierID}).pipe(
      map<DynamicApiResponse, SupplierDetails>((response) => {
        return new SupplierDetails(response.body);
      })
    );
  }

  public getAddressTypes(): Observable<SupplierAddressType[]> {
    return this.callDynamicAPI('supplier/getAddressTypes').pipe(
      map<DynamicApiResponse, SupplierAddressType[]>((response) => {
        return response.body.map((s: any) => new SupplierAddressType(s));
      })
    );
  }

  public getContactTypes(): Observable<SupplierContactType[]> {
    return this.callDynamicAPI('supplier/getContactTypes').pipe(
      map<DynamicApiResponse, SupplierContactType[]>((response) => {
        return response.body.map((s: any) => new SupplierContactType(s));
      })
    );
  }

  public manageSupplierContacts(supplierID: number, supplierContacts?: ISupplierDetailsContact[]): Observable<any> {
    return this.postDynamicAPI('supplier/manageSupplierContact', {SupplierID: supplierID, SuppCont: supplierContacts}).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body
      })
    )
  }

  public manageSupplierAddresses(supplierID: number, supplierAddresses?: ISupplierDetailsAddress[]): Observable<any> {
    return this.postDynamicAPI('supplier/manageSupplierAddress', {SupplierID: supplierID, SupplierAddr: supplierAddresses}).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body
      })
    )
  }

  public getCashReceiptTypes(companyID?: number): Observable<ResReceiptType[]> {
    return this.callDynamicAPI('receiptType/select', { CompanyID: companyID }).pipe(
      map<DynamicApiResponse, ResReceiptType[]>((response) => {
        return response.body.map((s: any) => new ResReceiptType(s));
      })
    );
  }

  
}
import { Component, Injectable } from '@angular/core';
import { EventType, NavigationExtras, NavigationStart, Router } from '@angular/router';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { EidosCommandErrorTypes, IEidosCommandPanelItem, IEidosCommandStatus } from './eidos-command-panel.model';

@Injectable({
  providedIn: 'root'
})
export class EidosCommandPanelService {

  public title: BehaviorSubject<string>;
  public titlePosition: BehaviorSubject<'Left'|'Middle'|'Right'>;
  public titleTooltip: BehaviorSubject<string>;
  public leftActions: BehaviorSubject<Array<IEidosCommandPanelItem>>;
  public rightActions: BehaviorSubject<Array<IEidosCommandPanelItem>>;
  public status: BehaviorSubject<IEidosCommandStatus>;
  public events = new BehaviorSubject('init');

  private currentRouteId: number = 0;
  private navigationCurrent?: string;
  private navigationHistoryBack: Array<string> = [];
  private navigationHistoryForward: Array<string> = [];

  public get validHistoryBack(): boolean {
    return this.navigationHistoryBack.length > 0;
  }
  public get validHistoryForward() {
    return this.navigationHistoryForward.length > 0;
  }
  constructor(
    private router: Router,
  ) {
    this.title = new BehaviorSubject<string>('');
    this.titlePosition = new BehaviorSubject<'Left'|'Middle'|'Right'>('Left');
    this.titleTooltip = new BehaviorSubject<string>('');
    this.leftActions = new BehaviorSubject<Array<IEidosCommandPanelItem>>([]);
    this.rightActions = new BehaviorSubject<Array<IEidosCommandPanelItem>>([]);
    this.status = new BehaviorSubject<IEidosCommandStatus>({
      visible: true,
      disabled: false,
      onWorking: false,
      enableBack: false,
      enableForward: false,
    } as IEidosCommandStatus
    );
    //intercept navigation changes
    this.router.events.subscribe((event:any) => {
      if (event.type === EventType.NavigationStart) {
        const ns = event as NavigationStart;
        this.title.next('');
        this.currentRouteId = ns.id;
        this.saveCurrent(this.navigationHistoryBack);
        this.navigationCurrent = ns.url
        this.removInvalidAction();
      }
    });
  }

  setTitle(title: string): void {
    this.title.next(title);
  }
  setTitleTooltip(title: string): void {
    this.titleTooltip.next(title);
  }
  setTitlePosition(position:'Left'|'Middle'|'Right'): void {
    this.titlePosition.next(position);
  }
  setTitleAndPosition(title: string,position:'Left'|'Middle'|'Right'): void {
    this.title.next(title);
    this.titlePosition.next(position);
  }
  addActions(actions: Array<IEidosCommandPanelItem>, functionContext?: any) {
    actions.forEach(a => {
      a._id = this.currentRouteId
      if (functionContext) {
        if (a.callback) a.callback = a.callback.bind(functionContext);
        if (a.isDisabled) a.isDisabled = a.isDisabled.bind(functionContext);
        if (a.tooltip && a.tooltip.active) a.tooltip.active = a.tooltip.active.bind(functionContext);
        if (a.tooltip && _.isFunction(a.tooltip.message)) a.tooltip.message = a.tooltip.message.bind(functionContext);
        if (a.badgeTooltip && a.badgeTooltip.active) a.badgeTooltip.active = a.badgeTooltip.active.bind(functionContext);
        if (a.badgeTooltip && _.isFunction(a.badgeTooltip.message)) a.badgeTooltip.message = a.badgeTooltip.message.bind(functionContext);
      }
    });
    const al = this.leftActions.getValue().filter(a => !actions.find(na => a.key === na.key));
    const allL = al.concat(actions.filter(a => a.position !== 'Right'));
    this.leftActions.next(allL);
    const ar = this.rightActions.getValue().filter(a => !actions.find(na => a.key === na.key));
    const allR = ar.concat(actions.filter(a => a.position === 'Right'));
    this.rightActions.next(allR);
    this.updateStatus();
  }
  addActionComponent(component: Component, action: IEidosCommandPanelItem) {
    action._id = this.currentRouteId;
    if (action.callback) action.callback = action.callback.bind(component);
    if (action.isDisabled) action.isDisabled = action.isDisabled.bind(component);
    if (action.tooltip && action.tooltip.active) action.tooltip.active = action.tooltip.active.bind(component);
    if (action.tooltip && _.isFunction(action.tooltip.message)) action.tooltip.message = action.tooltip.message.bind(component);
    if (action.badgeTooltip && action.badgeTooltip.active) action.badgeTooltip.active = action.badgeTooltip.active.bind(component);
    if (action.badgeTooltip && _.isFunction(action.badgeTooltip.message)) action.badgeTooltip.message = action.badgeTooltip.message.bind(component);
    const partAction = action.position === 'Right' ? this.rightActions : this.leftActions;
    const a = partAction.getValue().filter(a => a.key !== action.key);
    a.push(action);
    partAction.next(a);
    this.updateStatus();
  }
  addAction(action: IEidosCommandPanelItem, functionContext?: any) {
    if (functionContext) {
      action._id = this.currentRouteId;
      if (action.callback) action.callback = action.callback.bind(functionContext);
      if (action.isDisabled) action.isDisabled = action.isDisabled.bind(functionContext);
      if (action.tooltip && action.tooltip.active) action.tooltip.active = action.tooltip.active.bind(functionContext);
      if (action.tooltip && _.isFunction(action.tooltip.message)) action.tooltip.message = action.tooltip.message.bind(functionContext);
      if (action.badgeTooltip && action.badgeTooltip.active) action.badgeTooltip.active = action.badgeTooltip.active.bind(functionContext);
      if (action.badgeTooltip && _.isFunction(action.badgeTooltip.message)) action.badgeTooltip.message = action.badgeTooltip.message.bind(functionContext);
    }
    const partAction = action.position === 'Right' ? this.rightActions : this.leftActions;
    const a = partAction.getValue().filter(a => a.key !== action.key);
    a.push(action);
    partAction.next(a);
    this.updateStatus();
  }
  private removInvalidAction() {
    console.log('COMMAND PANEL - removInvalidAction', event);
    const al = this.leftActions.getValue().filter(a => a._id === this.currentRouteId);
    this.leftActions.next(al);
    const ar = this.rightActions.getValue().filter(a => a._id === this.currentRouteId);
    this.rightActions.next(ar);
    this.updateStatus();
    this.events.next('removInvalidAction')
  }
  removeActionByKey(key: string) {
    const al = this.leftActions.getValue().filter(a => a.key !== key);
    this.leftActions.next(al);
    const ar = this.rightActions.getValue().filter(a => a.key !== key);
    this.rightActions.next(ar);
    this.updateStatus();
  }
  removeAction(action: IEidosCommandPanelItem) {
    const al = this.leftActions.getValue().filter(a => a.key !== action.key);
    this.leftActions.next(al);
    const ar = this.rightActions.getValue().filter(a => a.key !== action.key);
    this.rightActions.next(ar);
    this.updateStatus();
  }
  removeAllActions() {
    this.leftActions.next([]);
    this.rightActions.next([]);
    this.updateStatus();
  }
  updateStatus() {
    const status = this.status.getValue();
    const visible = this.leftActions.getValue().length > 0
      || this.rightActions.getValue().length > 0
      || (status.enableBack && this.navigationHistoryBack.length > 0)
      || (status.enableForward && this.navigationHistoryForward.length > 0);
    if (status.visible !== visible) {
      status.visible = visible;
      this.status.next(status);
    }
  }
  hideCommandPannel(){
    const status = this.status.getValue();
    status.visible = false;
    this.status.next(status);
  }
  showCommandPannel() {
    this.updateStatus();
  }
  enableBackButton(): void {
    const status = this.status.getValue();
    if (status.enableBack) return;

    status.enableBack = true;
    status.visible = this.leftActions.getValue().length > 0
      || this.rightActions.getValue().length > 0
      || (status.enableBack && this.navigationHistoryBack.length > 0)
      || (status.enableForward && this.navigationHistoryForward.length > 0);
    this.status.next(status);
  }
  enableBackForward(): void {
    const status = this.status.getValue();
    if (status.enableForward) return;

    status.enableForward = true;
    status.visible = this.leftActions.getValue().length > 0
      || this.rightActions.getValue().length > 0
      || (status.enableBack && this.navigationHistoryBack.length > 0)
      || (status.enableForward && this.navigationHistoryForward.length > 0);
    this.status.next(status);
  }
  notifyerror(_: unknown, self: IEidosCommandPanelItem) {
    //@TODO ADD log error and notify logic
    switch (self.errorBehavior?.errorType) {
      case EidosCommandErrorTypes.Silent:
        break;
      case EidosCommandErrorTypes.Dialog:
        break;
      case EidosCommandErrorTypes.Toast:
        break;
      default:
        break;
    }
  }
  statusStartWorking(_: IEidosCommandPanelItem) {
    const status = this.status.getValue();
    status.onWorking = true;
    this.status.next(status);
  }
  statusStopWorking(_: IEidosCommandPanelItem) {
    const status = this.status.getValue();
    status.onWorking = false;
    this.status.next(status);
  }
  clearHistoryNav() {
    this.navigationHistoryBack = [];
    this.navigationHistoryForward = [];
  }
  private parseUrl(url: string) {
    const parts = url.split('?');
    const extra = { queryParams: {} } as NavigationExtras;
    if (parts.length > 1) parts[1].split('&').forEach(item => {
      const kvp = item.split('=');
      (extra.queryParams as any)[kvp[0]] = kvp.length > 1 ? kvp[1] : '';
    });
    return { url: [parts[0]], extra: extra };
  }
  saveCurrent(history: Array<string>) {
    if (!this.navigationCurrent) return;

    if (this.navigationHistoryBack.length > 0 && this.navigationHistoryBack[this.navigationHistoryBack.length - 1] === this.navigationCurrent) return;

    if (this.navigationHistoryForward.length > 0 && this.navigationHistoryForward[this.navigationHistoryForward.length - 1] === this.navigationCurrent) return;

    history.push(this.navigationCurrent);
  }
  goBack() {
    const url = this.navigationHistoryBack.pop() ?? '';
    this.saveCurrent(this.navigationHistoryForward);
    const parts = this.parseUrl(url);
    this.router.navigate(parts.url, parts.extra);
  }
  goForward() {
    const url = this.navigationHistoryForward.pop() ?? '';
    this.navigationHistoryBack.push(url);
    const parts = this.parseUrl(url);
    this.router.navigate(parts.url, parts.extra);
  }
  getAllActions(){
    return [...this.leftActions.getValue(), ...this.rightActions.getValue()]
  }
}

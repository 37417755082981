import { SafeBaseObject } from "@app/core/models/base-object.models";
import { DateTime } from "luxon";


export interface IGeoTreeDestination {
  GeoTreeID: number;
  GeoTreeName: string;
  GeoTreeTypeID: number;
  DestinationTypeID: number;
  LEVEL_1_ID: number;
  LEVEL_2_ID: number;
  LEVEL_3_ID: number;
  LEVEL_4_ID: number;
  LEVEL_5_ID: number;
  ExternalCityID: number;
  Search?: number;
  IsVisible: boolean;
}


export class GeoTreeSearch extends SafeBaseObject {
  description: string = '';
  details: GeoTreeSearchDetails[] = [];
  destinationType: string = '';
  level1ID: number = 0;
  level2ID?: number = 0;
  masterImages: any[] = [];
  name: string = '';
  order: number = 0;
  isExpanded?: boolean = false;
  parentID?: number = 0;


  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
  updateData(data?: any) {
    if (!data) return;
    if (data.Details) this.details = data.Details.map((d: any) => new GeoTreeSearch(d));

  }
}

export class GeoTreeSearchDetails extends SafeBaseObject {
  level1ID: number = 0;
  level2ID: number = 0;
  name: string = '';
  order: number = 0;
  isChecked: boolean = false;

  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
}


export class GeoTreeTopicList extends SafeBaseObject {
  topicID = 0
  topic = ''
  subjects: GeoTreeTopicSubject[] = []
  invoice = false
  reservation	 = false
  webSite	 = false
  documentation = false
  needCountry = false
  needDuration = false

  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }

  updateData(data?: any) {
    if (!data) return;
  }
}

export class GeoTreeTopicSubject extends SafeBaseObject {
  topicID = 0
  subjectID = 0
  subject = ''
  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
  updateData(data?: any) {
    if (!data) return;
  }
}
export interface IResApiVoyageDocument {
  VoyageDocumentationID?: number;
  CompanyID?: number;
  StartDate?: DateTime;
  EndDate?: DateTime;
  TopicID?: number;
  TopicName?: string;
  SubjectID?: number;
  SubjectName?: string;
  GuestPassportCountryCode?: string;
  Notes?: string;
  DestinationID?: number;
  DestinationName?: string;
  IsExcludeDestination?: string;
  IsToDestination?: string;
  IsFromDestination?: string;
  IsThrougthDestination?: string;
  ProductTypeID?: number;
  ProductTypeName?: string;
  PackageID?: number;
  PackageName?: string;
  DurationFrom?: number;
  DurationTo?: number;
  IsInvoiceDisplay?: string;
  IsReservationDisplay?: string;
  IsWebsiteDisplay?: string;
  IsDocumentationDisplay?: string;
  Status?: string;
  WrittenBy?: string;
  AllProductTypes?: string;
  AllDestinations?: string;
}

export interface IResVoyageDocument {
  VoyageDocumentationID?: number;
  CompanyID?: number;
  CompaniesID: { CompanyID: number; CompanyName?: string; }[];
  CompanyName?: string;
  StartDate?: DateTime;
  EndDate?: DateTime;
  TopicID?: number;
  TopicName?: string;
  SubjectID?: number;
  SubjectName?: string;
  GuestPassportCountryCode?: string;
  Notes?: string;
  DestinationID?: number;
  DestinationName?: string;
  IsExcludeDestination?: boolean;
  IsToDestination?: boolean;
  IsFromDestination?: boolean;
  IsThrougthDestination?: boolean;
  ProductTypeID?: number;
  ProductTypeName?: string;
  PackageID?: number;
  PackageName?: string;
  DurationFrom?: number;
  DurationTo?: number;
  IsInvoiceDisplay?: boolean;
  IsReservationDisplay?: boolean;
  IsWebsiteDisplay?: boolean;
  IsDocumentationDisplay?: boolean;
  Status?: string;
  WrittenBy?: string;
  AllProductTypes?: boolean;
  AllDestinations?: boolean;
}

export class ResVoyageDocument extends SafeBaseObject {
  voyageDocumentationID?: number
  companyID = 0;
  companyName = ''
  companiesID: number[] = [];
  startDate?: DateTime
  endDate?: DateTime

  topicID = 0
  topicName = ''
  subjectID?: number = 0
  subjectName = ''
  guestPassportCountryCode = ''
  notes = ''
  destinationID?:number = 0
  destinationName = ''
  isExcludeDestination: boolean = false
  isToDestination: boolean = false
  isFromDestination: boolean = false
  isThrougthDestination: boolean = false
  productTypeID = 0
  keys: number[] = [] 
  productTypeName = ''
  packageID = 0
  packageName = ''
  durationFrom = 0
  durationTo = 0
  isInvoiceDisplay: boolean = false
  isReservationDisplay: boolean = false
  isWebsiteDisplay: boolean = false
  isDocumentationDisplay: boolean = false
  status = ''
  writtenBy = ''
  allProductTypes: boolean = false
  allDestinations: boolean = false

  constructor(data?: IResVoyageDocument) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
      this.keys = [this.productTypeID];
    }
  }

  updateData(data?: IResVoyageDocument) {
    if (!data) return;
  }
}

export interface IDynamicMapApiParams {
  bkgID?: number
  PackageID?:number
}

export class ResDynamicMapResponse extends SafeBaseObject {

  statusCode = 0
  error = false
  response:any = {}
  location = ''

  constructor(data?: any) {
    super()
    if (data) {
      super.updateData(data)
      this.updateData(data)
    }
  }
  updateData(data?: any) {
    if (!data) return
  }
}

export interface IResGeoTreeCountryAndContinent {
  GeoTreeID?: number;
  GeoTreeName?: string;
  DestinationTypeID?: number;
  DestinationTypeName?: string;
  ExternalCityID?: number;
  ExternalCity?: string;
}

export interface IResAPIGetGeoTreeCountryAndContinent {
  GeoTreeID?: number;
  GeoTreeName?: string;
}

export interface IResGeoTreeCountryAndContinent {
  GeoTreeID?: number;
  GeoTreeName?: string;
  DestinationTypeID?: number;
  DestinationTypeName?: string;
  ExternalCityID?: number;
  ExternalCity?: string;
}

export class ResGeoTreeCountryAndContinent extends SafeBaseObject {
  geoTreeID = 0
  geoTreeName = ''
  destinationTypeID = 0
  destinationTypeName = ''
  externalCityID = 0
  externalCity = ''

  constructor(data?: IResGeoTreeCountryAndContinent) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }

  updateData(data?: IResGeoTreeCountryAndContinent) {
    if (!data) return;
  }
}

export interface IResAPIGetGeoTreeCountryAndContinent {
  GeoTreeID?: number;
  GeoTreeName?: string;
}



export class ResBkgProductType extends  SafeBaseObject {
  productTypeID: number = 0;
  productTypeName: string = '';
  isDefault: boolean = false;

  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
      this.addBooleanProperty('isDefault', data, 'IsDefault')
    }
  }

  updateData(data?: any) {
    if (!data) return;
  }
}

export class ResTopicItinerary extends SafeBaseObject {
  destinationID: number = 0;
  destinationName: string = '';
  topic: string = '';
  subject: string = '';
  notes: string = '';

  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }

  updateData(data?: any) {
    if (!data) return;
  }
}

export interface IResApiGetGeoTreeTopicItinerary {
  BkgID?: number;
  VoyageID?: number;
  PackageID?: number;
}

export class ResBkgVisaHealth extends SafeBaseObject {
  destinationID = 0	
  destinationName	= ''
  topic	= ''
  subject	= ''
  notes = ''

  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }

  updateData(data?: any) {
    if (!data) return;
  }
}

export class ResVoyageDescription extends SafeBaseObject {
  entIns = ''
  voyageNumber = ''
  voyageName = ''
  voyageDescription = ''
  voyageIncluded = ''
  portAgentEmbark = ''
  portAgentDebark = ''
  pierEmbark = ''
  pierDebark = ''
  groundOpEmbark = ''
  groundOpDebark = ''
  formalNights = ''

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);

  }
}

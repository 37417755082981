import { BaseObject, SafeBaseObject } from "@app/core/models/base-object.models";
import { CoreFormatService } from "@app/core/services/core-format.service";
import { IReservationServiceType } from "@app/reservation/models/res-cached-data.models";
import { DateTime } from "luxon";
import { RescomService } from "../services/rescom.service";

export class ReservationSetupService extends SafeBaseObject {
    address?: string;
    brandID?: number;
    city?: string;
    cityID?: number;
    companyID?: number;
    country?: string;
    countryCod?: string;
    countyCod?: string;
    currency?: string;
    discontinueDays?: number;
    discontinueDaysCru?: number;
    hasIncluded?: boolean;
    serviceDescription?: string;
    priceSetupID?: number;
    serviceID?: number;
    serviceLongName?: string;
    serviceLedGerCode?: string;
    serviceShortName?: string;
    serviceTermsInclusions?: string;
    serviceTermsExclusions?: string;
    serviceTypeID?: number;
    serviceTypeName?: string;
    siteID?: number;
    stateCod?: string;
    status?: string;
    supplierID?: number;
    supplierName?: string;
    zip?: string;
    transferIDFrom?: number;
    transferIDTo?: number;
    transferTypeIDFrom?: number;
    transferTypeIDTo?: number;
    pierFrom?: string;
    pierTo?: string;
    hotelUniqueID?: number;
    hotelName?: string;
    geoTreeID?: number;
    latitude?: number;
    longitude?: number;
    nearestAirportID?: number;
    longDescription?: string;

    serviceIDTS?: number;
    companyIDTS?: number;

    companiesIDs?: number[]; // used for the Get
    companiesID?: string; // used for the Manage

    get formattedStatus(): string {
        switch (this.status) {
            case 'A': return 'Active';
            case 'D': return 'Deleted';
            default: return '';
        }
    }

    constructor(data?: IReservationApiSetupService) {
        super();

        this.hasIncluded = false;
        this.status = 'A';

        if (data) {
            this.updateData(data);
            if (data.Companies) this.companiesIDs = data.Companies.map(c => Number(c.CompanyID))
        }
    }

    override updateData(data: IReservationApiSetupService): void {
        this.addMangledProperty(data);
        this.addBooleanProperty('hasIncluded', data, 'HasIncluded');
    }

}

export interface IReservationSetupServiceCompanies {
    CompanyID: number;
    CompanyName: string;
    ServiceID?: number;
}

export interface IReservationApiSetupService {
    Address?: string;
    City?: string;
    CityID?: number;
    Companies?: IReservationSetupServiceCompanies[];
    Country?: string;
    CountryCod?: string;
    CountyCod?: string;
    Currency?: string;
    DiscontinueDays?: string;
    DiscontinueDaysCru?: string;
    HasIncluded?: string;
    PriceSetupID?: number;
    ServiceDescription?: string;
    ServiceID?: number;
    ServiceLongName?: string;
    ServiceLedGerCode?: string;
    ServiceShortName?: string;
    ServiceTermsInclusions?: string;
    ServiceTermsExclusions?: string;
    ServiceTypeID?: number;
    ServiceTypeName?: string;
    SiteID?: number;
    StateCod?: string;
    Status?: string;
    SupplierID?: number;
    SupplierName?: string;
    Zip?: string;
    TransferIDFrom?: number;
    TransferIDTo?: number;
    TransferTypeIDFrom?: number;
    TransferTypeIDTo?: number;
    PierFrom?: string;
    PierTo?: string;
    HotelUniqueID?: number;
    HotelName?: string;
    GeoTreeID?: number;
    Latitude?: number;
    Longitude?: number;
    NearestAirportID?: number;
    LongDescription?: string;
}

export class ReservationServiceType extends SafeBaseObject {
    serviceTypeID?: number;
    serviceTypeName?: string;
    serviceTypeDescription?: string;
    productCod?: string;
    isSelected: boolean;
    guestConstraint?: boolean;
    tabsLocation?: string;
    onBoardUse?: boolean;
    hasCruise?: boolean;
    defaultValue = DateTime.invalid('empty');

    get displayName() {
        return `${this.serviceTypeName} - ${this.serviceTypeDescription}`
    }
    constructor(data: IReservationServiceType) {
        super();
        this.isSelected = false;
        if (data) {
            this.updateData(data);
        }
    }

    override updateData(data: IReservationServiceType): void {
        this.addMangledProperty(data);
        this.addBooleanProperty('guestConstraint', data, 'GuestConstraint');
        this.addBooleanProperty('onBoardUse', data, 'OnBoardUse');
        this.addBooleanProperty('hasCruise', data, 'HasCruise');
    }
}

export class SupplierAddressType extends SafeBaseObject {
    supplierLookupID = 0;
    scope = '';
    position = 0;
    cod = '';
    desc = '';

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}
export class SupplierContactType extends SafeBaseObject {
    supplierLookupID = 0;
    scope = '';
    position = 0;
    cod = '';
    desc = '';

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}

export class SupplierDetails extends SafeBaseObject {
    addresses: SupplierDetailsAddress[] = [];
    contacts: SupplierDetailsContact[] = [];

  constructor(data?: any) {
    super();
    if (data) {
    //   this.updateData(data);
      this.addresses = data.DT0.map((address: any) => new SupplierDetailsAddress(address))
      this.contacts = data.DT1.map((contact: any) => new SupplierDetailsContact(contact))
    }
  }
}

export class SupplierDetailsAddress extends SafeBaseObject {
    supplierID = 0;
    supplierAddressID = 0;
    addressTypeCod = '';
    address = '';
    cityID = 0;
    countryCod = '';
    stateCod = '';
    countyCod = '';
    zip = ''; 
    phone = '';
    fax = '';
    emergencyPhone = '';
    email  = '';
    webURL = '';
    note = '';
    status = 'A';
    
    constructor(data?: any) {
      super();
      if (data) {
        this.updateData(data);
      }
    }
}

export class SupplierDetailsContact extends SafeBaseObject {
    supplierID = 0;
    supplierContactID = 0;
    contactTypeCod = '';
    title = '';
    firstName = '';
    middleName = '';
    lastName = '';
    jobTitle = '';
    department = '';
    phone = '';
    workPhone = '';
    fax = '';
    emergencyPhone = '';
    email  = '';
    note = '';
    status = 'A';
    
    constructor(data?: any) {
      super();
      if (data) {
        this.updateData(data);
      }
    }
}

export interface ISupplierDetailsAddress {
    SupplierAddressID : number;
    SupplierID: number;
    AddressTypeCod: string;
    Address: string;
    CityID: number;
    CountryCod: string;
    StateCod: string;
    CountyCod: string;
    Zip: string; 
    Phone: string;
    Fax: string;
    EmergencyPhone: string;
    Email : string;
    WebURL: string;
    Note: string;
    Status: string;
}

export interface ISupplierDetailsContact {
    SupplierContactID: number;
    SupplierID: number;
    ContactTypeCod: string;
    Title: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    JobTitle: string;
    Department: string;
    Phone: string;
    WorkPhone: string;
    Fax: string;
    EmergencyPhone: string;
    Email : string;
    Note: string;
    Status: string;
}

export interface IReservationApiServiceType {
    ServiceTypeName?: string,
    ServiceTypeID?: number,
    BkgID?: number
}

export interface IReservationGeotreeCityPicklist {
    DestinationTypeID: number
    DestinationTypeName: string
    ExternalCityID: number | null
    ExternalCity: string | null
    GeoTreeID: number
    GeoTreeName: string
}

export class ReservationServiceData extends BaseObject {
    serviceTypeID?: number;
    serviceTypeName?: string;
    serviceTypeDescription?: string;
    guestConstraint?: boolean;
    serviceID?: number;
    serviceLongName?: string;
    serviceShortName?: string;
    serviceDescription?: string;
    serviceDisplayNameUnique?: string;
    /* options?: ReservationServiceOption[];
    filteredOptions?: ReservationServiceOption[]; */
    inclusiveItems?: ReservationServiceOption[]
    filteredInclusiveItems?: ReservationServiceOption[]
    supplierName?: string;
    country?: string;
    serviceDisplayName?: string;

    constructor(
        data?: IReservationApiService,
        coreFormatService?: CoreFormatService
    ) {
        super(data);
        if (!!data) {
            this.updateData(data, coreFormatService);
        }
    }

    override updateData(
        data: IReservationApiService,
        coreFormatService?: CoreFormatService
    ): void {
        this.addMangledProperty(data);
        this.addBooleanProperty('guestConstraint', data, 'GuestConstraint');
        this.inclusiveItems =
            data.InclusiveItems?.map(
                (option) => new ReservationServiceOption(option, coreFormatService)
            ) ?? [];

        if (!this.inclusiveItems?.length) {
            this.filteredInclusiveItems = [];
        } else {
            let options = this.inclusiveItems.filter(
                (o, i, a) => a.findIndex((x) => x.optionID === o.optionID) === i
            );


            options.forEach((o) => {
                o.allAvailableOptions = (this.inclusiveItems
                    ?.filter((x) => x.optionID === o.optionID)) ?? [];
            });
            this.filteredInclusiveItems = options;
        }

    }
}

export class ReservationServiceOption extends BaseObject {
    serviceTypeID?: number;
    serviceTypeName?: string;
    serviceTypeDescription?: string;
    guestConstraint?: boolean;
    serviceID?: number;
    serviceLongName?: string;
    serviceShortName?: string;
    serviceName?: string;
    serviceDescription?: string;
    supplierName?: string;
    optionID?: number;
    optionCod?: string;
    minPax?: number;
    maxPax?: number;
    needMessage?: boolean;
    csFlag?: boolean;
    optionName?: string;
    optionShortName?: string;
    optionDesc?: string;
    estimatedCost?: number;
    cost?: number;
    costTypeID?: number;
    costCurrency?: string;
    priceSetUp?: string;
    priceSetupID?: number;
    sellingPrice?: number;
    sellingPriceCurrency?: string;
    sellingTax?: number;
    priceCurrency?: string;
    currency?: string;
    margin?: number;
    date?: DateTime;
    selfIncluded?: boolean;
    iNCServiceTypeID?: number;

    _allAvailableOptions: ReservationServiceOption[] = [this];
    get allAvailableOptions(): ReservationServiceOption[] {
        return this._allAvailableOptions;
    }
    set allAvailableOptions(value: ReservationServiceOption[]) {
        this._allAvailableOptions = value;
        this.itineraryDateStart = [...this.availableDates][0];
        this.itineraryDateEnd = [...this.availableDates].at(-1);
    }

    _itineraryDateStart?: DateTime;
    _itineraryDateEnd?: DateTime;
    valueType?: 'Amount' | 'Percentage';
    formattedPrice?: string;
    formattedSellingTax?: string;
    formattedCost?: string;
    formattedDate?: string;
    startDate?: DateTime;
    endDate?: DateTime;
    message: string = '';
    isIncluded?: boolean;
    prePostFlag?: string
    _onCheckIn?: boolean;
    _onCheckOut?: boolean;
    availableOnCheckIn?: boolean;
    availableOnCheckOut?: boolean;
    onBoardUse?: boolean; // provided by the service type and setted by the FE
    CommissionableSO?: boolean;
    onHoldQTY?: number;
    guestCod?: string;
    onRequest?: boolean;
    quantityToAdd?: number = 1;
    checked?: boolean = false;

    availableSelectedOptionsDetail: IReservationOptionDetail[] = []

    coreFormatService?: CoreFormatService;

    // if the option is inclusive itineraryDateStart and itineraryDateEnd are checkIn and checkOut dates, otherwise they are the selected daterange

    constructor(
        data: IReservationApiServiceOption,
        coreFormatService?: CoreFormatService,
    ) {
        super(data);
        this.coreFormatService = coreFormatService
        this.updateData(data);
    }

    override updateData(
        data: IReservationApiServiceOption,
    ): void {
        this.addMangledProperty(data);
        this.addDateTimeProperty('date', data, 'Date');
        this.addDateTimeProperty('startDate', data, 'StartDate');
        this.addDateTimeProperty('endDate', data, 'EndDate');
        this.addBooleanProperty('needMessage', data, 'NeedMessage');
        this.addBooleanProperty('csFlag', data, 'CsFlag');
        this.addBooleanProperty('isIncluded', data, 'IsIncluded');
        this.addBooleanProperty('commissionableSO', data, 'CommissionableSO');
        this.addBooleanProperty('onBoardUse', data, 'OnBoardUse');
        this.addBooleanProperty('selfIncluded', data, 'SelfIncluded');
        this.addBooleanProperty('onRequest', data, 'OnRequest');

        this.formattedPrice = this.formatOptionPrices(
            'sellingPrice',
            this.coreFormatService
        );
        this.formattedSellingTax = this.formatOptionPrices(
            'sellingTax',
            this.coreFormatService
        );
        this.formattedCost = this.formatOptionPrices('cost', this.coreFormatService);
        this.formattedDate = this.formatOptionDate(this.coreFormatService);

        this.recalculateSelectedOptionsDetail();
    }

    get onCheckIn(): boolean | undefined {
        return this._onCheckIn;
    }
    set onCheckIn(value: boolean | undefined) {
        this._onCheckIn = value;
        if (value) this.itineraryDateStart = this.availableDates[0];
        else if (this.isIncluded) this.itineraryDateStart = undefined;
    }

    get onCheckOut(): boolean | undefined {
        return this._onCheckOut;
    }
    set onCheckOut(value: boolean | undefined) {
        this._onCheckOut = value;
        if (value) this.itineraryDateEnd = this.availableDates.at(-1);
        else if (this.isIncluded) this.itineraryDateEnd = undefined;
    }

    get itineraryDateStart(): DateTime | undefined {
        return this._itineraryDateStart;
    }
    set itineraryDateStart(value: DateTime | undefined) {
        this._itineraryDateStart = value;
        this.recalculateSelectedOptionsDetail()

    }

    get itineraryDateEnd(): DateTime | undefined {
        return this._itineraryDateEnd;
    }
    set itineraryDateEnd(value: DateTime | undefined) {
        this._itineraryDateEnd = value;
        this.recalculateSelectedOptionsDetail()
    }


    public get isPercentage(): boolean {
        return this.valueType?.toLowerCase() === 'percentage';
    }

    public get isAmount(): boolean {
        return this.valueType?.toLowerCase() === 'amount';
    }

    public get isPre(): boolean {
        return this.prePostFlag?.toLowerCase() === 'pre';
    }

    public get isPost(): boolean {
        return this.prePostFlag?.toLowerCase() === 'post';
    }

    public get isPrePost(): boolean {
        return this.prePostFlag?.toLowerCase() === 'all';
    }

    public get availableForAllGuests(): boolean {
        return this.guestCod?.toLowerCase() === 'all';
    }

    public get availableGuestCods(): string[] {
        return this.guestCod?.split(',') || [];
    }

    public get availableDates(): DateTime[] {
        return this.allAvailableOptions.map((o) => o.date).filter(d => d?.isValid).sort((a, b) => +(a ?? 0) - +(b ?? 0)) as DateTime[];
    }

    public formatOptionPrices(
        type: string,
        coreFormatService?: CoreFormatService
    ): string {
        if (!this[type] && this[type] !== 0) return '';

        if (this.isPercentage) return `${this[type]}%`;

        if (!coreFormatService) return typeof this[type] === 'number' ? this[type].toString() : this[type];

        const currency = type == 'cost' ? this.costCurrency : this.priceCurrency;

        return coreFormatService.CurrencyAmount(this[type], currency);
    }

    public formatOptionDate(coreFormatService?: CoreFormatService): string {
        if (!this.date || !coreFormatService) return '';
        return coreFormatService.DateToDefaultDateFormat(this.date);
    }

    public formatOptionAvailableDates() {
        return this.availableDates.map((d) => d.toJSDate());
    }

    public get hasOnlyOneDateAvailable(): boolean {
        return this.availableDates.length === 1;
    }

    public getPriceByDate(date: DateTime): number {
        const option = this.getAvailableOptionByDate(date);
        return option ? this.getFormattedPriceNumber(option) : 0;
    }

    public getAvailableOptionByDate(date: DateTime): ReservationServiceOption | undefined {
        if (!date?.isValid) return undefined;
        return this.allAvailableOptions.find((o: ReservationServiceOption) => +(o.date ?? -1) === +date);
    }

    public getItineraryDateRange(): DateTime[] {
        if (!this.itineraryDateStart) return [];
        return RescomService.buildDateRange(this.itineraryDateStart, this.itineraryDateEnd);
    }

    public getAvailableSelectedOptions(): ReservationServiceOption[] {
        if (!this.allAvailableOptions?.length) return [];

        if (this.isIncluded && !this.onBoardUse) {
            if (!this.itineraryDateStart && !this.itineraryDateEnd) return [];
            if (this.onCheckIn && this.onCheckOut && this.itineraryDateStart && this.itineraryDateEnd) {
                return [this.getAvailableOptionByDate(this.itineraryDateStart), this.getAvailableOptionByDate(this.itineraryDateEnd)].filter((o) => !!o) as ReservationServiceOption[];
            }
            if (this.onCheckIn && this.itineraryDateStart) {
                return [this.getAvailableOptionByDate(this.itineraryDateStart)].filter((o) => !!o) as ReservationServiceOption[];
            }
            if (this.onCheckOut && this.itineraryDateEnd) {
                return [this.getAvailableOptionByDate(this.itineraryDateEnd)].filter((o) => !!o) as ReservationServiceOption[];
            }
            return [];
        }

        return this.getItineraryDateRange().map((d) => this.getAvailableOptionByDate(d)).filter((o) => !!o) as ReservationServiceOption[];
    }

    public getAvailableSelectedOptionsDetail(): IReservationOptionDetail[] {
        return this.getAvailableSelectedOptions().map(option => ({ sellingPrice: option.sellingPrice, formattedPrice: option.formattedPrice, formattedDate: option.formattedDate, formattedCost: option.formattedCost, formattedSellingTax: option.formattedSellingTax, serviceTypeID: this.serviceTypeID, serviceID: option.serviceID, optionID: option.optionID, priceCurrency: option.priceCurrency, date: option.date, isIncluded: this.isIncluded, valueType: option.valueType, onHoldQTY: option.onHoldQTY, isPercentage: this.isPercentage, onRequest: option.onRequest })).filter((detail, i, a) => a.findIndex(detail2 => +(detail.date ?? 0) === +(detail2.date ?? 0)) === i) as IReservationOptionDetail[];
    }

    public getTotalPrice(factor?: number): string {
        if (this.isPercentage) return this.sellingPrice + '%'

        //const currency = this.sellingPriceCurrency
        const currency = this.priceCurrency

        let priceNumber = this.availableSelectedOptionsDetail.reduce((acc, cur) => acc + (this.getFormattedPriceNumber(cur) ?? 0), 0)

        if (factor != undefined) priceNumber = priceNumber * factor

        return this.coreFormatService?.CurrencyAmount(priceNumber, currency) ?? ''
    }

    public getFormattedPriceNumber(option: ReservationServiceOption | IReservationOptionDetail): number {
        if (!option.formattedPrice) return 0;
        return RescomService.getNumberFromString(option.formattedPrice)
    }

    public recalculateSelectedOptionsDetail() {
        this.availableSelectedOptionsDetail = this.getAvailableSelectedOptionsDetail()
    }

    public get minOnHoldQTY(): number {
        if (!this.availableSelectedOptionsDetail.length) return 0
        return Math.min(...(this.availableSelectedOptionsDetail.map(o => o.onHoldQTY).filter(o => o) as number[]))
    }

    public isDateAvailable(date: DateTime): boolean {
        if (!date?.isValid) return false
        return this.availableDates.some((d: DateTime) => +d === +date)
    }
}

export class ReservationServiceOptionGrouped extends ReservationServiceOption {
    dates: DateTime[] = []
}


export interface IReservationApiService {
    ServiceTypeID?: number;
    ServiceTypeName?: string;
    ServiceTypeDescription?: string;
    GuestConstraint?: string;
    ServiceID?: number;
    ServiceLongName?: string;
    ServiceShortName?: string;
    ServiceDescription?: string;
    InclusiveItems?: IReservationApiServiceOption[];
    SupplierID?: number;
    SupplierName?: string;
    OptionName?: string;
    ServiceDisplayNameUnique?: string;
    Country?: string;
    ServiceDisplayName?: string;
}

export interface IReservationOptionDetail {
    serviceTypeID?: number;
    serviceID?: number;
    optionID?: number;
    sellingPrice?: number,
    date?: DateTime,
    formattedPrice?: string,
    formattedDate?: string,
    formattedCost?: string,
    formattedSellingTax?: string,
    priceCurrency?: string,
    valueType?: string,
    isIncluded?: boolean,
    onHoldQTY?: number,
    isPercentage?: boolean,
    onRequest?: boolean
    quantityToAdd?: number
  }

export interface IReservationApiGetServices {
  ServiceID?: number;
  ServiceLongName?: string;
  ServiceShortName?: string;
  ServiceDescription?: string;
  ServiceMaximumOccupancy?: number;
  ServiceTypeID?: number;
  SupplierID?: number;
  CompanyID?: number;
  SiteID?: number;
  SupplierName?: string;
  Country?: string;
  CityID?: number;
  CompanyID_OVERWRITE?: number;
}

export interface IReservationApiServiceOption {
    ServiceTypeID?: number;
    ServiceTypeName?: string;
    ServiceTypeDescription?: string;
    GuestConstraint?: boolean;
    ServiceID?: number;
    ServiceLongName?: string;
    ServiceShortName?: string;
    ServiceName?: string;
    ServiceDescription?: string;
    SupplierName?: string;
    OptionID?: number;
    OptionCod?: string;
    MinPax?: number;
    MaxPax?: number;
    NeedMessage?: string;
    CsFlag?: string;
    OptionName?: string;
    OptionShortName?: string;
    OptionDesc?: string;
    EstimatedCost?: number;
    Cost?: number;
    CostTypeID?: number;
    CostCurrency?: string;
    PriceSetUp?: string;
    SellingPrice?: number;
    SellingPriceCurrency?: string;
    SellingTax?: number;
    PriceCurrency?: string;
    Currency?: string;
    Margin?: number;
    Date?: string;
    ValueType?: 'Amount' | 'Percentage';
    StartDate?: string;
    EndDate?: string;
    Message?: string;
    PrePostFlag?: string;
    CommissionableSO?: string;
    OnHoldQTY?: number;
    GuestCod?: string;
    OnBoardUse?: boolean;
    IsIncluded?: string;
    SelfIncluded?: string;
    INCServiceTypeID?: number;
    OnRequest?: boolean;
  }
import { SafeBaseObject } from '@app/core/models/base-object.models';
import { DateTime } from 'luxon';

export class ResPromo extends SafeBaseObject {
  static INVALID_PROMO_ID = 0;

  endDate: DateTime = DateTime.now();
  pricesType: IPriceType[] = [];
  promoID: number = 0;
  promoName: string = '';
  startDate: DateTime = DateTime.now();
  status: string = 'A';
  promoType: string = '';
  type: string = '';

  // promo detail properties from IReservationApiPromoDetail
  aSFlag: boolean = false;
  cOFlag: boolean = false;
  isAgency: boolean = false;
  isAutomatic: boolean = false;
  isDirect: boolean = false;
  resVisibility: boolean = false;
  shortDescription: string = "";
  longDescription: string = "";
  termAndCondition: string = "";
  webSiteVisibility: boolean = false;
  pricesTypeDistinct: IPriceType[] = [];
  currencyCodDistinct: string[] = [];
  pricesTypeIdDistinct: number[] = [];
  promoTypeID: number = 0;
  promoCombinabilityID: number = 0;
  promoCombinability: string = "";
  actionTypeID: number = 0;
  promoActionType: string = "";
  companyID: number = 0;
  isComplete: boolean = false;
  promoActionTypeID = 0;
  voyageNumber = '';
  voyageID = '';
  suiteCategoryID = 0;
  priceCategoryID = 0;
  priceSingle = 0;
  priceDouble = 0;
  priceExtraGuest = 0;
  priceChild = 0;
  portCharge = 0;
  totalPrice = 0;
  isSelected = false;
  isPreSelect = false
  isDisabled = false
  isCategoryPromo = false
  promoProductTypeID = 0
  soloTravel = false

  constructor(data?: IReservationApiPromoSetup | IReservationApiPromoDetail) {
    super();
    if (data) {
      if(data) this.updateData(data);
      if(this.actionTypeID===0)this.actionTypeID=this.promoActionTypeID;
      if(this.pricesType) this.createDistinctObjects(this.pricesType)
      this.selectPromoContext()
      this.isCategoryPromo = this.promoActionTypeID === 2
    }
  }

  selectPromoContext() {
    //@TODO add bussines logi wiyh constraint check
    this.isSelected = this.isPreSelect;
  }
  createDistinctObjects(pricesType: any[]) {
    const pricesTypeList: any = [];
    const currencyCodsList: string[] = [];
    pricesType.forEach(el => {
      pricesTypeList.push({PriceTypeId: el.PriceTypeID, PriceTypeName: el.PriceTypeName, PriceTypeCod: el.PriceTypeCod})
      currencyCodsList.push(el.CurrencyCod);
    })

    const uniquePricesTypeList = [...new Map(pricesTypeList.map((v: any) => [v.PriceTypeId, v])).values()]
    const uniqueCurrencyCodsList = [...new Set(currencyCodsList)];

    uniquePricesTypeList.forEach((uniqueEl: any) => {
      uniqueEl.Currencies = [];
      pricesType.forEach((el:any) => {
        if(el.PriceTypeID === uniqueEl.PriceTypeId) {
          uniqueEl.Currencies.push(el.CurrencyCod);
        }
      })
    })

    // this.pricesTypeDistinct = uniquePricesTypeList;
    this.pricesTypeDistinct = JSON.parse(JSON.stringify(pricesType));
    this.pricesTypeIdDistinct = uniquePricesTypeList.map((el: any) => { return el.PriceTypeId });
    this.currencyCodDistinct = uniqueCurrencyCodsList;
  }

  override updateData(data: IReservationApiPromoSetup | IReservationApiPromoDetail): void {
    this.addMangledProperty(data);
    this.pricesType = data.PricesType;

    // const currenciesID = this.pricesType.map((el) => el.CurrencyCod);
    // this.currenciesID = currenciesID.filter((v, i, a) => a.indexOf(v) === i);

    this.promoType = data.Type
  }

  get formattedPricesType() {
    const priceTypeCodListTemp = [];
    for (let priceType of this.pricesType) {
      priceTypeCodListTemp.push(priceType.PriceTypeCod);
    }

    const pricesTypeCodListUnique = [...new Set(priceTypeCodListTemp)];

    return pricesTypeCodListUnique;
  }

  get formattedStatus() {
    switch (this.status) {
      case 'A':
        return 'Active';
      case 'B':
        return 'Draft';
      case 'D':
        return 'Deleted';
      default:
        return 'ERROR STATUS';
    }
  }

  get isDeleted() {
    return this.status === 'D'
  }

  get isValid(): boolean {
    return !!this.promoID && this.promoID !== ResPromo.INVALID_PROMO_ID;
  }
}

export interface IPromoParams {
  PromoID: number;
  VoyageId: string;
  Description: string;
  PriceTypeID?: string;
  Status: string;
  AgencyCod: string;
  DateFrom: DateTime;
  DateTo: DateTime;
  PromoHdr?: number;
  // UserName: string;
  PageNum?: number;
  PageSize?: number;
}

export interface IPriceType {
  CurrencyCod: string;
  PriceTypeCod?: string;
  PriceTypeID: number;
  PriceTypeName?: string;
  Promo2FareID?: number;
  PromoID: number;
  Status: string;
}

export interface IReservationApiPromoSetup {
  EndDate: string;
  PricesType: IPriceType[];
  PromoID: number;
  PromoName: string;
  StartDate: string;
  Status: string;
  Type: string;
}

export interface IReservationApiPromoDetail {
  ASFlag: string;
  COFlag: string;
  EndDate: DateTime;
  IsAgency: string;
  IsAutomatic: string;
  IsDirect: string;
  PricesType: IPriceType[];
  PromoID: number;
  PromoName: string;
  ResVisibility: string;
  ShortDescription: string;
  LongDescription: string;
  StartDate: string;
  Status: string;
  TermAndCondition: string;
  Type: string;
  WebSiteVisibility: string;
  PromoTypeID: number;
  CompanyID:number;
  PromoActionTypeID: number;
  PromoActionType: string;
  VoyageID:string;
  VoyageNumber:string;
  SuiteCategoryID:number;
  PriceCategoryID:number;
  PriceSingle:number;
  PriceDouble:number;
  PriceExtraGuest:number;
  PriceChild:number;
  PortCharge:number;
  TotalPrice:number;
  SoloTravel:string;
}

export interface IReservationApiManagePromoParams {
  PromoID: number | null,
  PromoName: string,
  ShortDescription: string,
  TermAndCondition: string,
  LongDescription: string,
  COFlag: string,
  ASFlag: string,
  IsDirect: string,
  IsAgency: string,
  WebSiteVisibility: string,
  ResVisibility: string,
  IsAutomatic: string,
  StartDate: DateTime,
  EndDate: DateTime,
  PromoTypeID: number,
  Status: string,
  PriceTypeId: string,
  CurrencyCod: string,
  PromoCombinabilityID: number,
  PromoCombinability: string,
  ActionTypeID: number;
  PromoActionType: string;
  CompanyID: number;
  PromoProductTypeID: number;
  SoloTravel: string;
}

export interface IReservationBkgPromoItemManagePramaters {
  BkgID:number,
  Promo:IReservationBkgPromoItemManageChanges[],
}
export interface IReservationBkgPromoItemManageChanges {
  PromoID:number,
  Remove:string
}
export interface ResPromoActionList {
  productCode: string,
  itemCode: string,
  itemDescription: string
  suiteType: string
}

export class ResPromoActionItems extends SafeBaseObject {

  firstGuest: IResPromoActionItem[] = [];
  secondGuest: IResPromoActionItem[] = [];
  firstTwoGuest: IResPromoActionItem[] = [];
  otherGuest: IResPromoActionItem[]= [];
  commissions: IResPromoActionCommission[] = [];

  constructor(data?: IReservationApiPromoItems) {
    super();
    if (data) {
      this.updateData(data);
      this.addDefaultData(this.firstGuest);
      this.addDefaultData(this.secondGuest);
      this.addDefaultData(this.firstTwoGuest);
      this.addDefaultData(this.otherGuest);
      this.addDefaultData(this.commissions);
    }
  }

  override updateData(data: IReservationApiPromoItems): void {
    this.addMangledProperty(data);
  }

  addDefaultData(dataList: IResPromoActionItem[]) {
    for(let item of dataList) {
      if(!item.Status) {
        item.Status = 'A';
      }
      if(!item.SuiteCategoryID) {
        item.SuiteCategoryID = 0;
        item.SuiteCategoryCod = 'ALL'
      }
    }
  }

  // generateListElementForEachSuiteCategory(dataList: IResPromoActionItem[]) {
  //   const temp = [];
  //   for(let el of dataList) {
  //     el.Status = 'A';
  //     if(el.SuiteCategory.length > 0) {
  //       for(let suiteCategoryElement of el.SuiteCategory) {
  //         const tempEl = JSON.parse(JSON.stringify(el));
  //         delete tempEl.SuiteCategory;
  //         tempEl.SuiteCategory = [suiteCategoryElement]
  //         temp.push(tempEl)
  //       }
  //     } else {
  //       temp.push(el);
  //     }
  //   }
  //   return temp;
  // }
}

export class ResBonusCommissionType extends SafeBaseObject {

  commissionType: string = '';
  commissionTypeID: number = 0;

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}

export interface IReservationApiPromoItems {
  FirstGuest: IResPromoActionItem[];
  FirstTwoGuest: IResPromoActionItem[]
  OtherGuest: IResPromoActionItem[]
  Commissions: IResPromoActionCommission[]
}

export interface IResPromoActionItem {
  GuestFrom?: number;
  GuestTo?: number;
  GuestType?: string;
  GuestTypeId?: number;
  OptionId?: number;
  OptionName?: string;
  Promo2ItemID?: number;
  PromoGuestType?: string
  PromoGuestTypeID?: number
  PromoID?: number;
  ServiceID?: number;
  ServiceLongName?: string;
  SuiteCategory?: string;
  SuiteCategoryCod?: string;
  SuiteCategoryID?: number;
  Status?: string;
}

export interface IResPromoActionCommission {
  CommissionGroupValue?: number;
  CommissionType?: string;
  CommissionTypeID?: number;
  CommissionTypeValue?: number;
  CommissionValue?: number;
  Promo2ItemID?: number;
  PromoID?: number;
  SuiteCategory?: string;
  SuiteCategoryCod?: string;
  SuiteCategoryID?: number;
  Status?: string;
}

  // export interface IResPromoActionSuiteCategoryItem {
//   Key: string;
//   PromoID: number;
//   Promo2ItemID: number;
//   OptionId: number;
//   ServiceId: number;
//   SuiteCategoryCod: string;
//   SuiteCategory: string;
//   SuiteCategoryID: number;
// }

export interface IReservationApiManagePromoActionParams {
  firstGuest: IReservationApiManagePromoActionItem[];
  secondGuest: IReservationApiManagePromoActionItem[];
  firstTwoGuest: IReservationApiManagePromoActionItem[];
  otherGuest: IReservationApiManagePromoActionItem[];
  commission: IReservationApiManagePromoActionItem[];
}

export interface IReservationApiManagePromoActionItem {
  Promo2ItemID?: number;
  PromoID?: number;
  ServiceID?: number;
  OptionID?: number;
  SuiteCategoryId?: number;
  GuestTypeID?: number;
  GuestFrom?: number;
  GuestTo?: number;
  Status?: string;
  GuestType?: string;
  CommissionTypeValue?: number;
  CommissionValue?: number;
  CommissionGroupValue?: number;
  CommissionTypeID?: number;
  CommissionType?: string;
}

export interface IReservationApiManagePromoVoyagesParams {
  Voyage: IReservationApiManagePromoVoyagesItem[];
  StartDate?: DateTime;
  EndDate?: DateTime;
}

export interface IReservationApiManagePromoVoyagesItem {
  PromoID: number;
  VoyageID: number;
  Status: string;
}

export interface IReservationApiManagePromoCompatibilityItem {
  PromoID: number;
  IncompatiblePromoID: number;
  Status: string;
}

export interface IReservationApiManagePromoCompatibilityParams {
  Promo2Promo: IReservationApiManagePromoCompatibilityItem[];
}

export interface IReservationPromoActionSuiteCategory {
  SuiteCategoryCod?: string | null;
  SuiteCategory?: string | null;
  SuiteCategoryID?: number | null;
}

export interface IPromoCombinability {
  PromoCombinabilityID: number;
  PromoCombinability: string;
}

export interface IResPromoHeader {
  Reason: string;
}

export class ResPromoHeader extends SafeBaseObject {
  reason: string = ''
  constructor(data?:IResPromoHeader) {
    super()
    if(data) {
      super.updateData(data);
    }
  }
}

export interface IReservationApiManagePromoAgenciesParams {
  Agency: IReservationApiManagePromoAgencyItem[];
  All?: number
}

export interface IReservationApiManagePromoAgencyItem {
  PromoID: number;
  AgencyCod: string;
  Status: string;
}

export enum ResPromoSelectSection {
  Unknow='Unknow',
  Inventory='Inventory',
  Book='Book'
}
export enum ResPromoSelectView {
  Button='button',
  Card='Card',
  Chooser='Chooser'
}

export interface IResResPromoSelectConfig {
  startView:ResPromoSelectView,
  section:ResPromoSelectSection,
  bkgID?:number,
  voyageID?:Array<number>;
  agencyCod?:string;
  currencyCod?:string;
  priceTypeID?:number;
  promoRequestedID?:string;
}

export interface IReservationPromoApplyPramaters {
  Section:ResPromoSelectSection,
  BkgID?:number,
  VoyageID?:string;
  CurrencyCod?:string;
  PriceTypeID?:number;
  PromoID?:string;
  AgencyCod?: string;
  AgencyID?: number;
  IndividualID?: number;
}
export interface IReservationApiPromoApply {
  PromoID:number;
  StartDate:Date;
  EndDate:Date;
  IsAutomatic:string;
  IsPreSelect:string;
  PromoName:string;
  PromoType:string;
  PromoTypeID:number;
  PromoCombinability:string;
  PromoCombinabilityID:number
  ShortDescription:string;
  TermAndCondition:string;
  ErrChk: string;
  ErrMsg: string;
  MatchReason: string;
  Selectable: string;

  Actions:Array<IReservationApiPromoApplyAction>;
  Voyages:Array<IReservationApiPromoApplyVoyage>;
  PriceTypes:Array<IReservationApiPromoApplyPriceType>;
}
export interface IReservationApiPromoApplyAction {
  GuestFrom:number;
  GuestTo:number;
  OptionID:number;

  OptionName: string;
  PromoID:number;
  ServiceID:number;
  ServiceName: string;
  SuiteCategory: string;
  SuiteCategoryID: number;
}
export interface IReservationApiPromoApplyVoyage {
  PromoID:number,
  VoyageID:number,
  VoyageNumber:string
}

export interface IReservationApiPromoApplyPriceType {
  CurrencyCod: string;
  PriceTypeCod: string;
  PriceTypeID: number;
  PriceTypeName: string;
  Promo2FareID: number;
  PromoID: number;
}

export class ReservationPromoApplyAction extends SafeBaseObject{
  guestFrom=0;
  guestTo=0;
  optionID=0;

  optionName='';
  promoID=0;
  serviceID=0;
  serviceName='';
  suiteCategory='';
  suiteCategoryID=0;
  constructor(data?:IReservationApiPromoApplyAction) {
    super()
    if(data) {
      super.updateData(data);
    }
  }
}
export class ReservationPromoApplyVoyage extends SafeBaseObject{
  promoID=0;
  voyageID=0;
  voyageNumber='';

  constructor(data?:IReservationApiPromoApplyVoyage) {
    super()
    if(data) {
      super.updateData(data);
    }
  }
}

export class ReservationPromoApplyPriceType extends SafeBaseObject {
  currencyCod = '';
  priceTypeCod = '';
  priceTypeID = 0;
  priceTypeName = '';
  promo2FareID = 0;
  promoID = 0;

  constructor(data?:IReservationApiPromoApplyPriceType) {
    super()
    if(data) {
      super.updateData(data);
    }
  }
}

export class ResPromoCompatibility extends SafeBaseObject {
  promoID = 0;
  promoName = '';
  startDate = DateTime.invalid('empty');
  endDate = DateTime.invalid('empty');
  status = '';
  constructor(data?:IResPromoCompatibility) {
    super()
    if(data) {
      super.updateData(data);
    }
  }
}

export interface IResPromoCompatibility {
  PromoID: number;
  PromoName: string;
  StartDate: Date;
  EndDate: Date;
  Status: string;
}

export interface IResPromoCompany {
  CompanyID: number;
  CompanyCode: string;
  ReportingCompanyName: string;
}

export class ResPromoCompany extends SafeBaseObject {
  companyID = 0;
  companyCode = "";
  reportingCompanyName = "";
  constructor(data?:IResPromoCompany) {
    super()
    if(data) {
      super.updateData(data);
    }
  }
}

export interface IResPromoSuiteCategory {
  SuiteCategoryID: number;
  SuiteCategory: string;
}

export class ResPromoSuiteCategory extends SafeBaseObject {
  suiteCategoryID = 0;
  suiteCategory = "";
  constructor(data?:IResPromoSuiteCategory) {
    super()
    if(data) {
      super.updateData(data);
    }
  }
}

export class ResPromoApply extends SafeBaseObject {
  promoID = 0;
  startDate = DateTime.invalid('not set');
  endDate = DateTime.invalid('not set');
  isSelected = false;
  isPreSelect = false;
  isDisabled = false;
  isAutomatic = false;
  promoName = '';
  promoType = '';
  promoTypeID = 0;
  promoActionTypeID = 0;
  promoCombinability = '';
  promoCombinabilityID = 0
  shortDescription = '';
  termAndCondition = '';
  errChk = false;
  errMsg = '';
  matchReason = '';
  selectable = false;
  collapseTC = true;
  isCategoryPromo = false



  actions:Array<ReservationPromoApplyAction>=[];
  voyages:Array<ReservationPromoApplyVoyage>=[];
  priceTypes:Array<ReservationPromoApplyPriceType>=[];
  priceTypesUnique: Array<string> = [];
  currenciesUnique: Array<string> = [];

  constructor(data?:IReservationApiPromoApply) {
    super()
    if(data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
  updateData(data?:IReservationApiPromoApply) {
    this.actions=[];
    this.voyages=[];
    this.priceTypes=[];
    if(!data) return;

    this.isSelected = this.isPreSelect;
    this.isCategoryPromo = this.promoActionTypeID === 2

    if(data.Actions) data.Actions.forEach(a=>this.actions.push(new ReservationPromoApplyAction(a)));
    if(data.Voyages) data.Voyages.forEach(v=>this.voyages.push(new ReservationPromoApplyVoyage(v)));
    if(data.PriceTypes) data.PriceTypes.forEach(p=>this.priceTypes.push(new ReservationPromoApplyPriceType(p)));
    if(data.PriceTypes) data.PriceTypes.forEach(pt => {
      if(!this.priceTypesUnique.includes(pt.PriceTypeName)) {
        this.priceTypesUnique.push(pt.PriceTypeName)
      }
      if(!this.currenciesUnique.includes(pt.CurrencyCod)) {
        this.currenciesUnique.push(pt.CurrencyCod)
      }
    })

    this.errChk = data.ErrChk === 'Y' ? true : false;
    this.errMsg = data.ErrMsg;
  }
  asResPromo() {
    const p = new ResPromo()
    p.promoID=this.promoID
    p.startDate = this.startDate
    p.endDate= this.endDate
    p.isSelected = this.isSelected
    p.isPreSelect = this.isPreSelect
    p.isDisabled = this.isDisabled
    p.isAutomatic = this.isAutomatic
    p.isCategoryPromo = this.isCategoryPromo
    p.promoName = this.promoName
    p.promoType = this.promoType
    p.promoActionTypeID = this.promoTypeID
    p.promoCombinability = this.promoCombinability
    p.promoCombinabilityID = this.promoCombinabilityID
    p.shortDescription = this.shortDescription
    p.termAndCondition = this.termAndCondition

    return p
  }
}


export interface IPromoType {
  PromoTypeID: number;
  PromoType: string;
}

export interface IPromoActionType {
  PromoActionTypeID: number;
  PromoActionType: string;
}

export interface IPromoStatus {
  StatusID: string;
  Status: string;
  PromoStatusID: number;
}

export interface IResProductApiPromoPrice {
  VoyageID:number
  PromoID:number
  PromoName:string
  PromoPrice:number
  PromoPriceCombo:number
}
export class ResProductPromoPrice extends SafeBaseObject {
  voyageID = 0
  productCod = ''
  productID = 0
  promoID = 0
  promoName = ''
  promoPrice = 0
  promoFormattedPrice?:string;
  promoPriceCombo = 0
  promoFormattedPriceCombo?:string;
  priceTypeID = 0
  companyName = ''

  constructor(data?:IResProductApiPromoPrice) {
    super()
    if(data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
  updateData(_data?:IResProductApiPromoPrice) {
    if(this.productCod==='CRU' && this.voyageID===0 && this.productID>0) this.voyageID=this.productID
  }

}


export interface IResPromoPriceType {
  PriceTypeID:number
  PriceTypeName:string
  PriceTypeCod:string
}
export class ResPromoPriceType extends SafeBaseObject {
  priceTypeID:number = 0
  priceTypeName:string = ''
  priceTypeCod:string = ''

  constructor(data?:IResPromoPriceType) {
    super()
    if(data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
}

export interface IResPromoShip {
  ShipID:number
  Ship:string
}

export class ResPromoShip extends SafeBaseObject {
  shipID = 0
  ship = ''

  constructor(data?:IResPromoShip) {
    super()
    if(data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
}

export class ResPromoPackage extends SafeBaseObject {
  packageID = 0
  packageName = ''
  packageTypeName = ''
  travelStartDate = DateTime.invalid('empty')
  travelEndDate = DateTime.invalid('empty')
  startDate = DateTime.invalid('empty')
  endDate = DateTime.invalid('empty')

  constructor(data?:any) {
    super()
    if(data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
}

export interface IResApiManagePromoPackages {
  Promo2Package?: IResApiManagePromoPackageDetail[]
  StartDate?: DateTime
  EndDate?: DateTime
}

export interface IResApiManagePromoPackageDetail {
  PromoID?: number
  PackageID?: number
  PackageTravelDateID?: number
  Status?: string
}

export class ResGetPromoBooking extends SafeBaseObject {
  bkgID = 0
  bkgStatus = ''
  bkgCurrency = ''
  priceTypeName = ''
  creationDate = DateTime.invalid('empty')
  travelDate = DateTime.invalid('empty')
  ownerType = ''
  agency = ''
  owner = ''
  company = ''

  constructor(data?:any) {
    super()
    if(data) {
      super.updateData(data);
      this.updateData(data);
    }
  }

}

export class ResPromoProperty extends SafeBaseObject {
  propertyID = 0
  productCod = ''
  ship = ''
  shipCod = ''
  cruiseLineID = 0
  paxNum = 0
  suite = ''
  startDate = DateTime.invalid('empty')
  endDate = DateTime.invalid('empty')
  propertyCityID = 0
  city = ''
  cityCity = ''
  countryID = 0
  countryName = ''
  countryCodISO3 = ''
  propertyGroupID = 0
  propertyGroupCod = ''
  propertyGroup = ''
  supplierID = 0
  supplierName = ''
  address = ''
  idts = ''
  description = ''
  currencyCod = ''
  
  isSelected = false

  constructor(data?:any) {
    super()
    if(data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
}

export interface IResApiSearchablePromoProperties {
  SearchText?: string
  Ship?: string
  ProductCod?: string
  CityID?: string
  Currency?: string
}

export interface IResApiManagePromoProperties {
 
}

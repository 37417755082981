import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, filter, map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import _ from 'lodash';

import { EidosConfigService } from '@common/config/eidos-config.service';
import { EidosBaseApiService } from '@common/services/eidos-base-api.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { EidosLogService } from '@common/services/eidos-log.service';
import { IEidosModuleConfiguration } from '@common/config/environment.interface';
import { DynamicApiResponse } from '@common/models/dynamic-api-response.model';
import { CoreFormatService } from '@common/services/core-format.service';

import {
  IReservationApiPayment,
  IResPaymentActionParams,
  IResPaymentApiEnquireInfo,
  IResPaymentApiRequest,
  IResPaymentApiRequestInfo,
  IResPaymentRequest,
  IResPaymentRequestConfirmation,
  IResPaymentRequestParams,
  ResNewPayment,
  ResPayment,
  ResPaymentPayer,
} from '../models/res-payment.model';
import { CoreBaseApiCommandParams } from '@app/core/models/core-base-api.model';
import { CoreModuleName, LocalStorageKeys } from '@app/core/models/core-constant.model';
import { IReservationModuleAPIConfiguration } from '@app/res-common/models/rescom.model';

interface IReservationPaymentAPIHeaders {
  kk_username?: string;
  kk_appcode?: 'RES';
  kk_company?: string;
  kk_companyID?: number;
  kk_site?: string;
}

class ApiPaymentEndPoint {
  private driver = ''
  private requestTemplate = 'request'
  private requestInfoTemplate ='requestInfo'
  private goBackTemplate = '{driver}goback'
  private enquireTemplate = 'enquire'
  get request() {
    return this.requestTemplate.replace('{driver}',this.driver)
  }
  get requestInfo() {
    return this.requestInfoTemplate
  }
  get goBack() {
    const driver = !!this.driver ? this.driver :'ncp/'
    return this.goBackTemplate.replace('{driver}',driver)
  }
  get enquire() {
    return this.enquireTemplate.replace('{driver}',this.driver)
  }
  setDriver(driver:string) {
    this.driver = driver + (!!driver ? '/' : '')
  }
}
@Injectable({
  providedIn: 'root',
})
export class ReservationPaymentApiService extends EidosBaseApiService {
  /**
   * Payment API URL
   *
   * @type {string}
   * @memberof ReservationPaymentApiService
   */
  private paymentAPI = '';
  private moduleCfg?:IReservationModuleAPIConfiguration
  private paymentEndPont:ApiPaymentEndPoint
  /**
   * Payment callback API URL
   *
   * @type {string}
   * @memberof ReservationPaymentApiService
   */
  private paymentCallbackAPI?: string = '';
  /**
   * Payment API headers
   *
   * @private
   * @type {IReservationPaymentAPIHeaders}
   * @memberof ReservationPaymentApiService
   */
  private paymentHeaders: IReservationPaymentAPIHeaders = {};

  constructor(
    public configService: EidosConfigService,
    public eidosSecurityService: EidosSecurityService,
    public http: HttpClient,
    public eidosLogService: EidosLogService,
    public dialog: MatDialog,
    public coreFormatService: CoreFormatService,
  ) {
    super(coreFormatService, configService, eidosLogService, dialog, eidosSecurityService, http);

    this.paymentEndPont = new ApiPaymentEndPoint()

    this.configService.currentModulesConfig
      .pipe(
        filter(
          (modules: Array<IEidosModuleConfiguration>) => !!modules.find((module) => module.moduleName === CoreModuleName.Reservation)
        )
      )
      .subscribe((modules) => {
        const module = modules.find((module) => module.moduleName === CoreModuleName.Reservation);

        if (module) {
          this.moduleCfg = module.API as IReservationModuleAPIConfiguration;
          this.urlAPI = this.moduleCfg.url;

          if(this.moduleCfg.usePaymentGateway) {
            this.switchToPaymentGateway()
          } else {
            this.switchToOldPayment()
          }

          this.apiVersion = this.moduleCfg.version;
          if (this.moduleCfg.headers) {
            this.headers = {
              ...this.moduleCfg.headers,
              ...this.headers
            };
          }
        }
      });
  }
  /**
   * Set payment API headers
   *
   * @param {IReservationPaymentAPIHeaders} headers
   * @memberof ReservationPaymentApiService
   */
  setPaymentHeaders(headers: IReservationPaymentAPIHeaders) {
    this.paymentHeaders = headers;
  }
  /**
   * Set payment API headers
   *
   * @param {IReservationPaymentAPIHeaders} headers
   * @memberof ReservationPaymentApiService
   */
  getPaymentHeaders(): IReservationPaymentAPIHeaders {

    const header:any ={
      ...this.paymentHeaders,
      kk_username: this.eidosSecurityService.getUser()?.username ?? '',
      kk_company: this.eidosSecurityService.getUser()?.company ?? '',
      kk_appcode: 'RES'
    }

    const token = localStorage.getItem(LocalStorageKeys.TOKEN)
    if(!!token) header[LocalStorageKeys.TOKEN] = localStorage.getItem(LocalStorageKeys.TOKEN)

    return header
  }
  switchToPaymentGateway() {
    if(!this.moduleCfg) return
    this.paymentAPI = this.moduleCfg.paymentGateway
    this.paymentCallbackAPI = this.moduleCfg.paymentGatewayCallback
    this.paymentEndPont.setDriver('adyen')
  }
  switchToOldPayment() {
    if(!this.moduleCfg) return
    this.paymentAPI = this.moduleCfg.paymentAPI
    this.paymentCallbackAPI = this.moduleCfg.paymentCallbackAPI
  }

  /**
   * Create a payment request
   * Return payment sending confirmation in postponed payment
   * Return payment full request in not postponed payment
   *
   * @param {IResPaymentRequestParams} params
   * @return {*}  {Observable<IResPaymentRequest | IResPaymentRequestConfirmation>}
   * @memberof ReservationPaymentApiService
   */
  createPayment(params: IResPaymentRequestParams): Observable<IResPaymentRequest | IResPaymentRequestConfirmation> {
    const commandParams = new CoreBaseApiCommandParams({
      url: this.paymentEndPont.request,
      data: params,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, IResPaymentRequest | IResPaymentRequestConfirmation>((response: IResPaymentApiRequest) => {

        const parseResult = (response: IResPaymentApiRequest) => {
          switch (response.Info[0].Result) {
            case 'OK':
            case 'WRN':
              return response.Info[0].Result;
            default:
              return 'ERR';
          }
        }

        const isPostponed = !!response.Info[0].IsPostponed;

        if (isPostponed) {

          let req: IResPaymentRequestConfirmation = {
            requestID: response.Info[0].RequestID,
            message: response.Info[0].Message,
            bkgID: response.Info[0].BkgID,
            result: parseResult(response),
            isPostponed: true
          };

          return req;

        } else {
          const parseMode = (response: IResPaymentApiRequest) => {
            if (response.FormModel) {
              return "form";
            } else if (response.IframeModel) {
              if(response.IframeModel.ForceSelfPage==='Y') return '_self'

              return "iframe";
            }

            return "unknown"
          }

          let req: IResPaymentRequest = {
            requestID: response.Info[0].RequestID,
            message: response.Info[0].Message,
            bkgID: response.Info[0].BkgID,
            result: parseResult(response),
            isPostponed: false,
            amount: response.Info[0].Amount,
            currency: response.Info[0].CurrencyCod,
            url: "",
            mode: parseMode(response)
          };

          if (req.result === 'OK') {

            if (req.mode !== "unknown") {
              switch (req.mode) {
                case "form":
                  if (response.FormModel!.Fields !== undefined) {
                    req.url = response.FormModel!.Url;
                    req.method = response.FormModel!.Method;
                    req.fields = Object.entries(response.FormModel!.Fields).map((item) => {
                      return {
                        key: item[0],
                        value: item[1],
                      };
                    });
                  }
                  break;
                case "iframe":
                case "_self":
                  if (response.IframeModel!.Url.startsWith('ERROR')) {
                    req.message = response.IframeModel!.Url;
                    req.result = 'ERR';
                    return req;
                  }
                  req.url = response.IframeModel!.Url;
                  break;
              }
            }

          } else {
            throw response.Info[0].Message;
          }

          return req;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        const response = new DynamicApiResponse(error);
        if (Array.isArray(response.errors) && response.errors.length > 0) {
          return response.errors;
        } else {
          return this.handleGenericError(error);
        }
      })
    );
  }
  /**
   * Retrieves a postponed payment
   *
   * @param {string} guid
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  postponedPayment(guid: string): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'postponedPayment',
      data: {
        Guid: guid,
      },
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, IResPaymentRequest>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const parseMode = (response: IResPaymentApiRequest) => {
          if (response.FormModel) {
            return "form";
          } else if (response.IframeModel) {
            return "iframe";
          }

          return "unknown"
        }

        const result = parseResult(response);

        if (result !== 'ERR') {

          let req: IResPaymentRequest = {
            requestID: response.Info[0].RequestID,
            message: response.Info[0].Message,
            bkgID: response.Info[0].BkgID,
            currency: response.Info[0].CurrencyCod,
            amount: response.Info[0].Amount,
            checkoutLayout: response.Info[0].CheckoutLayout ?? response.CheckoutLayout,
            result: result,
            isPostponed: !!response.Info[0].IsPostponed,
            url: "",
            mode: parseMode(response)
          };

          if (req.mode !== "unknown") {
            switch (req.mode) {
              case "form":
                if (response.FormModel!.Fields !== undefined) {
                  req.url = response.FormModel!.Url;
                  req.method = response.FormModel!.Method;
                  req.fields = Object.entries(response.FormModel!.Fields).map((item) => {
                    return {
                      key: item[0],
                      value: item[1],
                    };
                  });
                }
                break;
              case "iframe":
                if (response.IframeModel!.Url.startsWith('ERROR')) {
                  req.message = response.IframeModel!.Url;
                  req.result = 'ERR';
                  return req;
                }
                req.url = response.IframeModel!.Url;
                break;
            }
          }
          return req;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }
      }),
      catchError((_: HttpErrorResponse) =>
        of({
          IsValid: false,
          Guid: '',
          Url: '',
          RequestID: '',
        })
      )
    );
  }
  /**
   * Checks payment request status
   *
   * @param {string} paymentReqID
   * @return {*}  {Observable<IResPaymentApiRequestInfo>}
   * @memberof ReservationPaymentApiService
   */
  checkPayment(paymentReqID: string): Observable<IResPaymentApiRequestInfo> {

    const commandParams = new CoreBaseApiCommandParams({
      url: this.paymentEndPont.requestInfo,
      data: { RequestID: paymentReqID },
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      catchError((error: HttpErrorResponse) => {
        const response = new DynamicApiResponse(error);
        if (Array.isArray(response.errors) && response.errors.length > 0) {
          return response.errors;
        } else {
          return this.handleGenericError(error);
        }
      })
    );
  }
  /**
   * Abort a payment request
   *
   * @param {string} paymentReqID
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  abortPayment(paymentReqID: string): Observable<IResPaymentApiRequestInfo> {

    const commandParams = new CoreBaseApiCommandParams({
      url: this.paymentEndPont.goBack,
      data: { RequestID: paymentReqID },
      type: 'POST',
      customBaseUrl: this.paymentCallbackAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      catchError((error: HttpErrorResponse) => {
        const response = new DynamicApiResponse(error);
        if (Array.isArray(response.errors) && response.errors.length > 0) {
          return response.errors;
        } else {
          return this.handleGenericError(error);
        }
      })
    );
  }
  /**
   * Enquire status pending payment (PN) not postPoned and update this when needed
   *
   * @param {string} bkgID
   * @return {*}  {Observable<IResPaymentApiRequestInfo>}
   * @memberof ReservationPaymentApiService
   */
  enquireStatus(orderID: number): Observable<IResPaymentApiEnquireInfo> {
    const commandParams = new CoreBaseApiCommandParams({
      url: this.paymentEndPont.enquire,
      data: { OrderID: orderID.toString() },
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<any, IResPaymentApiEnquireInfo>((response) => {
        let msg = ''
        switch(response.StatusPayment) {
          case "OK":
            msg =  `Payment check and charged`
            break;
          case "EX":
            msg =  `Payment check and NOT charged`
            break;
          default:
            msg =  `Payment check and it's in status ${response.StatusPayment}`
            break;
        }
        return {
          hasError: response.StatusPayment === "EX",
          status: response.StatusPayment,
          message: msg,
          result: response.XMLResponse
        } as IResPaymentApiEnquireInfo
      })
    );
  }
  /**
   * Force process payment request in status PR
   *
   * @param {string} bkgID
   * @return {*}  {Observable<IResPaymentApiRequestInfo>}
   * @memberof ReservationPaymentApiService
   */
  processPayment(bkgID: number): Observable<string> {
    // return this.callDynamicAPI('account/userinfo', {}, 'GET').pipe(
    //   map<DynamicApiResponse, string>((response) =>
    //     Array.isArray(response.body) && response.body.length > 0 ? (response.body[0].Result as string) : 'KO')
    // );
    const commandParams = new CoreBaseApiCommandParams({
      url: 'RequestProcess',
      data: { BkgID: bkgID.toString() },
      type: 'GET',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<any, string>((response) => {
        if (response.FoundErrors) return 'KO';
        return response.Message as string;
      }),
      catchError((error: HttpErrorResponse) => {
        const response = new DynamicApiResponse(error);
        if (Array.isArray(response.errors) && response.errors.length > 0) {
          console.log(response.errors);
        }
        return 'KO';
      })
    );
  }
  /**
   * Void a payment request
   *
   * @param {IResPaymentActionParams} voidPaymentReq
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  voidPayment(voidPaymentReq: IResPaymentActionParams): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'void',
      data: voidPaymentReq,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, string>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const result: string = parseResult(response);

        if (result === 'OK') {
          return result;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }
      }),
      catchError((error: HttpErrorResponse) => this.handleGenericError(error))
    );
  }

  /**
     * Charge Back a payment request
     *
     * @param {IResPaymentActionParams} refundPaymentReq
     * @return {*}  {Observable<any>}
     * @memberof ReservationPaymentApiService
     */

  chargeBackPayment(refundPaymentReq: IResPaymentActionParams) {
    const tmpReq = {
      'ReceiptDescription': refundPaymentReq.Reason,
      'OrderID': refundPaymentReq.OrderID,
      'BkgID': refundPaymentReq.BkgId
    };
    return this.postDynamicAPI('payment/Chargeback', tmpReq ).pipe(
      map<DynamicApiResponse, any>((response) => response.body
      )
    );
  }

  createPaymentLink(bkgID: number, bkgCurrency: string) {
    const commandParams = new CoreBaseApiCommandParams({
      url: 'CreatePaymentRequest',
      data: { BkgID: bkgID.toString(), BkgCurrency: bkgCurrency },
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<any, boolean>((response) =>  response.status === 'OK' )
    );

  }


  /**
     * Refund a payment request
     *
     * @param {IResPaymentActionParams} refundPaymentReq
     * @return {*}  {Observable<any>}
     * @memberof ReservationPaymentApiService
     */

  refundManualPayment(refundPaymentReq: IResPaymentActionParams) {
    const tmpReq = {
      'ReceiptTotal': refundPaymentReq.Amount,
      'ReceiptDate': refundPaymentReq.Data,
      'ReceiptDescription': refundPaymentReq.Reason,
      'OrderID': refundPaymentReq.OrderID,
      'BkgID': refundPaymentReq.BkgId
    };
    return this.postDynamicAPI('payment/manualRefund', tmpReq ).pipe(
      map<DynamicApiResponse, any>((response) => response.body
      )
    );
  }

  refundManualPayment2(refundPaymentReq: IResPaymentActionParams): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'payment/manualRefund',
      data: refundPaymentReq,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, string>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const result: string = parseResult(response);

        if (result === 'OK') {
          return result;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }

      }),
      catchError((error: HttpErrorResponse) => this.handleGenericError(error))
    );
  }
  /**
   * Refund a payment request
   *
   * @param {IResPaymentActionParams} refundPaymentReq
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  unrefundPayment(unrefundPaymentReq: IResPaymentActionParams): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'UnrefRefund',
      data: unrefundPaymentReq,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, string>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const result: string = parseResult(response);

        if (result === 'OK') {
          return result;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }
      }),
      catchError((error: HttpErrorResponse) => this.handleGenericError(error))
    );
  }
  /**
   * Refund a payment request
   *
   * @param {IResPaymentActionParams} refundPaymentReq
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  refundPayment(refundPaymentReq: IResPaymentActionParams): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'refund',
      data: refundPaymentReq,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, string>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const result: string = parseResult(response);

        if (result === 'OK') {
          return result;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }
      }),
      catchError((error: HttpErrorResponse) => this.handleGenericError(error))
    );
  }
  /**
   * Repay a payment request
   *
   * @param {IResPaymentActionParams} repayPaymentReq
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  repayPayment(repayPaymentReq: IResPaymentActionParams): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'repay',
      data: repayPaymentReq,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, string>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const result: string = parseResult(response);

        if (result === 'OK') {
          return result;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }
      }),
      catchError((error: HttpErrorResponse) => this.handleGenericError(error))
    );
  }
  /**
   * Gets payment history of a booking
   *
   * @param {number} bkgID
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  getPaymentHistory(bkgID: number): Observable<any> {
    return this.getDynamicAPI('payment/history', { BkgID: bkgID }).pipe(
      map<DynamicApiResponse, [ResPayment[], ResPayment[]]>((response) => {
        const payments = response.body.Payments.map((p: IReservationApiPayment) => new ResPayment(p));
        const links = response.body.Link.map((p: IReservationApiPayment) => new ResPayment(p));
        return [payments, links];
      })
    );
  }

  getPaymentHistoryNew(bkgID: number): Observable<any> {
    return this.getDynamicAPI('payment/historyNew', { BkgID: bkgID }).pipe(
      map<DynamicApiResponse, [ResPayment[], ResPayment[], ResNewPayment[], ResPaymentPayer[]]>((response) => {
        const payments = response.body.DT0?.map((p: IReservationApiPayment) => new ResPayment(p)) ?? [];
        const links = response.body.DT1?.map((p: IReservationApiPayment) => new ResPayment(p)) ?? [];
        const newPayments:ResNewPayment[] = response.body.DT2?.map((p: any) => new ResNewPayment(p)) ?? [];
        const payers:ResPaymentPayer[] = response.body.DT3?.map((p: any) => new ResPaymentPayer(p)) ?? [];
        if(payers.length>0) {
          newPayments.filter(p=>p.payer.payerGroupID===0)
                     .forEach(p=>p.payer = Object.assign({},payers.find(po=>po.payerGroupID===p.payerGroupID) ?? payers[0]))
        }
        return [payments, links, newPayments, payers];
      })
    );
  }
  /**
   * Gets payment details for OrderId
   *
   * @param {string} orderID
   * @return {*}
   * @memberof ReservationPaymentApiService
   */
  getPayment(orderID: string) {
    return this.getDynamicAPI('payment/get', { OrderID: orderID }).pipe(
      map<DynamicApiResponse, IResPaymentRequestParams | undefined>((response) => {
        if (response.body?.Payment !== undefined) {
          return response.body.Payment as IResPaymentRequestParams;
        } else {
          return undefined;
        }
      })
    );
  }

  /**
   * retrive key to cript data
   * retrive key to cript data
   *
   * @param {string} paymentReqID
   * @return {*}  {Observable<IResPaymentApiRequestInfo>}
   * @memberof ReservationPaymentApiService
   */
  acquireKey(shared:string): Observable<string[]> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'AquireKey',
      data: { Shared: shared },
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<any, string[]>((response) => {
        if (response.FoundErrors) return [];
        return [response.Key as string,response.Guid as string];
      }),
      catchError((error: HttpErrorResponse) => {
        const response = new DynamicApiResponse(error);
        if (Array.isArray(response.errors) && response.errors.length > 0) {
          return response.errors;
        } else {
          return this.handleGenericError(error);
        }
      })
    );
  }


}

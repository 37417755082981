import { SafeBaseObject } from "@app/core/models/base-object.models";

import { ResBookingUtility } from "../../reservation/models/res-booking-utility.model";
import { ResBooking } from "../../reservation/models/res-booking.model";
import { EidosConfigService } from "@app/core/config/eidos-config.service";
import { AppModule } from "@app/app.module";
import { filter, map } from "rxjs";
import { IEidosModuleConfiguration } from "@app/core/config/environment.interface";
import { CoreModuleName } from "@app/core/models/core-constant.model";
import { IReservationEnvironment } from "@app/reservation/models/res-environment.interface";

export interface IReservationApiPermission {
  Login: string;
  IsAdmin: boolean;
  IsInventory: boolean;
  IsPowerUser: boolean;
  Permission: Array<{ Login: string, PermissionCod: string }>;
}

export enum ReservationAction {
  CreateTicket,
  CreateGuest,
  AccessReservation,
  ViewBookingOptionsCost,
  EditAirGateways,
  ViewManifest,
  ViewCommissionPayout,
  AccessBookingDocument,
  AccessBookingCommission,
  AccessBookingPayment,
  OpenCloseSuiteCategory,
  CanNotSeeCommission,
  CanSendGuestToTS,
  CanInsertExternalAirReference,
  CanNoSuiteRequired,
  PackageCloneDates,
  BookingReferralDelete,
  BookingReferralView,
  BookingManualReferral,
  GeoTreeEdit,
  GeoTreeEditAddNewItem,
  GeoTreeEditDeleteItem,
  GeoTreeCloneItems,
  CanGoToPayableCycle,
  CanMakeToRequest,
  CanGenerateDynamicMap,
  CanBookingTouchNotes,
  CanMakeLandRequest,
  CanMakeShorexRequest,
  OpenInventoryFromVoyage,
}

export enum ReservationActionBooking {

  CloneBooking,
  EditBooking,
  EditBookingNotes,
  ExtendJourneyBooking,
  PromoteBooking,
  ConfirmBooking,
  DeleteBooking,
  CopyBooking,
  LinkBooking,

  EditBookingOptions,

  HandleCustomPackageBooking,

  EditCommissionAmountBooking,
  EditCommissionPercBooking,

  EditBookingGuestAir,
  SearchAirFlights,
  BookAirFlights,
  DeleteAirPnrs,
  DeleteTicketedAirPnrs,
  RefreshAirPnrs,
  UploadAirPnrs,
  ViewImportPnr,
  ChangePnrAirClass,
  AirDeptAdmin,
  EditBookingCruisePrice,
  RecalculateBooking,
  PayWithLinkOnBooking,
  TransferCashOnBooking,
  AddOptionsBookingVersion,

  ManageGuestBookingVersion,
  OpenGuestInCRM,
  ManageGuestInsuranceBooking,
  ManageGuestPreferencesBooking,
  ActOnBhealfOfGuest,

  DeleteOptionsBookingVersion,
  UpgradePackageBookingVersion,
  AddVoyageBookingVersion,
  ChangeVoyageBookingVersion,
  ChangeSuiteBookingVersion,
  UpgradeSuiteBookingVersion,
  CloneBookingVersion,
  PayOnBooking,
  RefundBooking,
  ManualRefund,
  VoidBooking,
  RepayBooking,
  UnrefRefundBooking,
  UnrefRefundBookingNoToken,
  ConvertOD,
  RefundOD,
  EditGuestBooking,
  ChangePriceTypeBooking,
  EditBookingGuestType,
  EditBookingPriceCategory,
  EditBookingFixedPrice,
  AcceptBookingSuiteOversize,
  DeleteOrEditBookingUserNote,
  BookingVersionWishlist,

  ViewBookingCertificate,
  CreateBookingCertificate,
  EditBookingCertificate,
  EditBookingCertificateExpirationDate,
  UseBookingCertificate,
  SearchBookingCertificate,
  ImportBookingCertificate,

  BookingMyActivities,
  DeleteBookingPackage,
  AddLand,
  UpgradePackage,
  Certificate_Admin,
  BookingReferralDiscounts,

  CanSplitPayer,
  CanDeleteBkgItems,
  CanEditBkgItems,
  CanOpenBkgExtendVoyageMenu,
  CanUseOneDocInvoce,
  CanUseOneDocProposal
}

export enum ReservationEditActionBooking {
  EditBookingConfirmationDate,
  EditBookingDepositDate,
  EditBookingFinalPaymentDate,
  EditBookingCreationDate,
  EditBookingGroup,
  EditBookingOwner,
  EditBookingOwnerEmail,
  EditBookingName,
  EditBookingAgentOwner,
  EditBookingPriceType,
  EditBookingCreatedBy,
  //EditBookingCurrency // TODO: uncomment to edit booking currency
}

export enum CodifiedPermissionCode {
  EditBooking = 'RES_EditBooking',

  EditBookingConfirmationDate = 'RES_EditBookingConfirmationDate',
  EditBookingDepositDate = 'RES_EditBookingDepositDate',
  EditBookingFinalPaymentDate = 'RES_EditBookingFinalPaymentDate',
  EditBookingCreationDate = 'RES_EditBookingCreationDate',
  EditForceBookingCreationDate = 'RES_EditForceBookingCreationDate',
  EditBookingGroup = 'RES_EditBookingGroup',
  EditBookingAgentOwner = 'RES_EditBookingAgentOwner',
  EditBookingOwner = 'RES_EditBookingOwner',
  EditBookingOwnerEmail = 'RES_EditBookingOwnerEmail',
  EditBookingGuestAir = 'RES_EditBookingGuestAir',
  //EditBookingCurrency = 'RES_EditBookingCurrency', // TODO: uncomment to edit booking currency
  EditBookingPriceType = 'RES_EditBookingPriceType',
  EditBookingCreatedBy = 'RES_EditBookingCreatedBy',
  CanEditBkgItems = 'RES_CanEditBkgItems',
  CanDeleteBkgItems = 'RES_CanDeleteBkgItems',

  EditAirGateways = 'RES_EditAirGateways',
  SearchAirFlights = 'RES_SearchAirFlights',
  BookAirFlights = 'RES_BookAirFlights',
  DeleteAirPnrs = 'RES_DeleteAirPnrs',
  DeleteTicketedAirPnrs = 'RES_DeleteTicketedAirPnrs',
  RefreshAirPnrs = 'RES_RefreshAirPnrs',
  UploadAirPnrs = 'RES_UploadAirPnrs',
  ViewImportPnr = 'RES_ViewImportPnr',
  ChangePnrAirClass = 'RES_ChangePnrAirClass',
  AirDeptAdmin = 'RES_AirDeptAdmin',
  EditBookingCruisePrice = 'RES_EditBookingCruisePrice',
  ConfirmBooking = 'RES_ConfirmBooking',
  PromoteBooking = 'RES_PromoteBooking',
  DeleteBooking = 'RES_DeleteBooking',
  LinkBooking = 'RES_LinkBooking',
  RecalculateBooking = 'RES_RecalculateBooking',
  CreateTicket = 'RES_CreateTicket',
  AccessReservation = "RES_AccessReservation",
  CanGenerateDynamicMap = 'RES_CanGenerateDynamicMap',
  EditBookingOptions = "RES_EditBookingOptions",
  ViewBookingOptionsCost = "RES_ViewBookingOptionsCost",
  EditCommissionAmount = "RES_EditCommissionAmount",
  EditCommissionPerc = "RES_EditCommissionPerc",
  PayWithLinkOnBooking = "RES_PayWithLinkOnBooking",
  PayOnBooking = "RES_PayOnBooking",
  TransferCashOnBooking = "RES_TransferCashOnBooking",
  RefundBooking = "RES_RefundBooking",
  UnrefRefundBooking = "RES_UnreferencedRefunds",
  UnrefRefundBookingNoToken = "RES_UnreferencedRefundsNoToken",
  ManualRefund = "RES_ManualRefund",
  VoidBooking = "RES_VoidBooking",
  RepayBooking = "RES_RepayBooking",
  ActOnReadOnlyBooking = "RES_ActOnReadOnlyBooking",
  EditGuestBooking = 'RES_EditGuestBooking',
  ChangePriceTypeBooking = 'RES_ChangePriceTypeBooking',
  AcquireLockByOther = 'RES_AcquireLockByOther',
  EditBookingGuestType = 'RES_EditBookingGuestType',
  EditBookingPriceCategory = 'RES_EditBookingPriceCategory',
  EditBookingFixedPrice = 'RES_EditBookingFixedPrice',
  AcceptBookingSuiteOversize = 'RES_AcceptSuiteOversize',
  V2VOnDeletedBooking = 'RES_V2VOnDeletedBooking',
  DeleteOrEditBookingUserNote = 'RES_EditBookingUserNote',
  ViewBookingCertificate = 'RES_ViewBookingCertificate',
  CreateBookingCertificate = 'RES_CreateBookingCertificate',
  EditBookingCertificate = 'RES_EditBookingCertificate',
  EditBookingCertificateExpirationDate = 'RES_EditBkgCertificateExpDate',
  UseBookingCertificate = 'RES_UseBookingCertificate',
  SearchBookingCertificate = 'RES_SearchBookingCertificate',
  ImportBookingCertificate = 'RES_ImportBookingCertificate',
  ViewManifest = 'RES_ViewManifest',
  ViewCommissionPayout = 'RES_CommissionPayout',
  ActOnBhealfOfGuest = 'RES_ActOnBhealfOfGuest',
  BookingMyActivities = "RES_BookingMyActivities",
  DeleteBookingPackage = "RES_DeleteBookingPackage",
  UpgradePackage = "RES_UpgradePackage",
  UpgradeSuiteBookingVersion = 'RES_UpgradeSuiteBookingVersion',
  OpenCloseSuiteCategory = 'RES_OpenCloseSuiteCategory',
  CanNotSeeCommission = 'RES_CanNotSeeCommission',
  CanSendGuestToTS = 'RES_CanSendGuestToTS',
  CanInsertExternalAirReference = 'RES_CanInsertExternalAirReference',
  CanNoSuiteRequired = 'RES_NoSuiteRequired',
  Certificate_Admin = 'RES_Certificate_Admin',
  PackageCloneDates = 'RES_PackageCloneDates',
  BookingReferralDelete = 'RES_BookingReferralCanDelete',
  BookingReferralView = 'RES_BookingReferralCanView',
  BookingManualReferral = 'RES_BookingManualReferral',
  BookingReferralDiscounts = 'RES_BookingReferralDiscounts',
  GeoTreeEdit = 'RES_GeoTreeEdit',
  GeoTreeEditAddNewItem = 'RES_GeoTreeEditAddNewItem',
  GeoTreeEditDeleteItem = 'RES_GeoTreeEditDeleteItem',
  GeoTreeCloneItems = 'RES_GeoTreeCloneItems',
  CanGoToPayableCycle = 'RES_PayableCycle',
  CanMakeToRequest = 'RES_CanMakeToRequest',
  CanSplitPayer = 'RES_CanSplitPayer',
  CanBookingTouchNotes = 'RES_CanBookingTouchNotes',
  PkgSetupAdmin = 'RES_PkgSetupAdmin',
  CanEditBookingSailed = 'RES_BookingCanEditSailed',
  CanMakeLandRequest = 'RES_CanMakeLandRequest',
  CanMakeShorexRequest = 'RES_CanMakeShorexRequest',
  OpenInventoryFromVoyage = 'RES_OpenInventoryFromVoyage'
}

export interface IReservationPermissionResponse {
  isAllowed: boolean;
  reason?: string;
}

export class ReservationPermission extends SafeBaseObject {
  /**
   * Booking utility reference
   * Instantiated only in real permissions
   *
   * @private
   * @type {ResBookingUtility}
   * @memberof ReservationPermission
   */
  private bookingUtility?: ResBookingUtility;
  /**
   * User's login
   *
   * @type {string}
   * @memberof ReservationPermission
   */
  public login: string = "";
  /**
   * Is user admin
   *
   * @type {boolean}
   * @memberof ReservationPermission
   */
  public isAdmin: boolean = false;
  /**
   * Is user of role inventory
   *
   * @type {boolean}
   * @memberof ReservationPermission
   */
  public isInventory: boolean = false;
  /**
   * Is user a Power User
   *
   * @type {boolean}
   * @memberof ReservationPermission
   */
  public isPowerUser: boolean = false;
  /**
   * Is user R/O
   *
   * @type {boolean}
   * @memberof ReservationPermission
   */
  isReadOnly: boolean = false;
  /**
   * User's permission
   *
   * @type {Array<string>}
   * @memberof ReservationPermission
   */
  public permission: Array<string> = [];

  static emptyPermission(): ReservationPermission {
    const resPermissions = new ReservationPermission();
    resPermissions.permission = [];
    return resPermissions;
  }

  static mockedPermission(): ReservationPermission {
    const resPermissions = new ReservationPermission();
    resPermissions.permission = Object.keys(CodifiedPermissionCode).map(k => `RES_${k}`);
    return resPermissions;
  }

  private eidosConfigService: EidosConfigService
  private isProductionEnvironment = false
  private oneDocConfig: { [key: string]: any | any[]; } = {}
  constructor(
      data?: IReservationApiPermission
    , bookingUtility?: ResBookingUtility
  ) {
    super();

    this.eidosConfigService = AppModule.injector.get(EidosConfigService);
    this.bookingUtility = bookingUtility;

    if (data) {
      this.updateData(data);
    }

    if (this.bookingUtility) {
      this.bookingUtility.bookingRetrievedInReadOnly = this.isReadOnly;
    }
    this.isProductionEnvironment = !!this.eidosConfigService.currentConfig.getValue().production
    this.eidosConfigService.currentModulesConfig
    .pipe(
      filter(
        (modules: Array<IEidosModuleConfiguration>) => !!modules.find((module) => module.moduleName === CoreModuleName.Reservation)
      ),
      map<Array<IEidosModuleConfiguration>,IReservationEnvironment>(modules=>modules[0] as IReservationEnvironment)
    )
    .subscribe(module => {
      this.oneDocConfig = module?.oneDoc ?? {}
    })
  }

  override updateData(data: IReservationApiPermission): void {
    this.addMangledProperty(data);
    this.permission = Array.isArray(data?.Permission) ? data.Permission.map(p => p.PermissionCod) : [];
  }

  static MissingPermission: IReservationPermissionResponse = { isAllowed: false, reason: 'Missing permission' };

  static UnknownAction: IReservationPermissionResponse = { isAllowed: false, reason: 'Unknown action' };

  static ReadOnlyUser: IReservationPermissionResponse = ReservationPermission.Deny('User has readonly role.');

  static Allowed: IReservationPermissionResponse = { isAllowed: true, reason: '' };
  /**
   * Creates a negative IResevationPermissionResponse
   *
   * @static
   * @param {string} reason
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  static Deny(reason: string): IReservationPermissionResponse {
    return { isAllowed: false, reason: reason } as IReservationPermissionResponse
  }
  /**
   * Check generic permission
   *
   * @param {ReservationAction} action
   * @return {*}  {boolean}
   * @memberof ReservationPermission
   */
  canI(action: ReservationAction): IReservationPermissionResponse {

    switch (action) {
      case ReservationAction.AccessReservation:
        if (this.hasPermission(CodifiedPermissionCode.AccessReservation)) return ReservationPermission.Allowed;
        break;
      case ReservationAction.CreateTicket:
        if (this.hasPermission(CodifiedPermissionCode.CreateTicket)) return ReservationPermission.Allowed;
        break;
      case ReservationAction.ViewBookingOptionsCost:
        if (this.hasPermission(CodifiedPermissionCode.ViewBookingOptionsCost)) return ReservationPermission.Allowed;
        break;
      case ReservationAction.EditAirGateways:
        return this.hasPermission(CodifiedPermissionCode.EditAirGateways) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.ViewManifest:
        return this.hasPermission(CodifiedPermissionCode.ViewManifest) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.ViewCommissionPayout:
        return this.hasPermission(CodifiedPermissionCode.ViewCommissionPayout) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.AccessBookingCommission:
      case ReservationAction.AccessBookingPayment:
        return this.isReadOnly ? ReservationPermission.ReadOnlyUser : ReservationPermission.Allowed;
      case ReservationAction.AccessBookingDocument:
        // RF 06/10/2023: Richiesto da Anna di escludere i documenti dal controllo degli utenti readonly
        return ReservationPermission.Allowed;
      case ReservationAction.OpenCloseSuiteCategory:
        return this.hasPermission(CodifiedPermissionCode.OpenCloseSuiteCategory) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.CanNotSeeCommission:
        return this.hasPermission(CodifiedPermissionCode.CanNotSeeCommission) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");

      case ReservationAction.CanSendGuestToTS:
        return this.hasPermission(CodifiedPermissionCode.CanSendGuestToTS) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.CanInsertExternalAirReference:
        return this.hasPermission(CodifiedPermissionCode.CanInsertExternalAirReference) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.CanNoSuiteRequired:
        return this.hasPermission(CodifiedPermissionCode.CanNoSuiteRequired) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.PackageCloneDates:
        return this.hasPermission(CodifiedPermissionCode.PackageCloneDates) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.BookingReferralDelete:
        return this.hasPermission(CodifiedPermissionCode.BookingReferralDelete) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.BookingReferralView:
        return this.hasPermission(CodifiedPermissionCode.BookingReferralView) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.BookingManualReferral:
          return this.hasPermission(CodifiedPermissionCode.BookingManualReferral) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.GeoTreeEdit:
        return this.hasPermission(CodifiedPermissionCode.GeoTreeEdit) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.GeoTreeEditAddNewItem:
        return this.hasPermission(CodifiedPermissionCode.GeoTreeEditAddNewItem) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.GeoTreeEditDeleteItem:
        return this.hasPermission(CodifiedPermissionCode.GeoTreeEditDeleteItem) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.GeoTreeCloneItems:
        return this.hasPermission(CodifiedPermissionCode.GeoTreeCloneItems) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.CanGoToPayableCycle:
        return this.hasPermission(CodifiedPermissionCode.CanGoToPayableCycle) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.CanMakeToRequest:
        return this.hasPermission(CodifiedPermissionCode.CanMakeToRequest) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.CanGenerateDynamicMap:
        return this.hasPermission(CodifiedPermissionCode.CanGenerateDynamicMap) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.CanBookingTouchNotes:
        return this.hasPermission(CodifiedPermissionCode.CanBookingTouchNotes) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.CanMakeLandRequest:
        return this.hasPermission(CodifiedPermissionCode.CanMakeLandRequest) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.CanMakeShorexRequest:
        return this.hasPermission(CodifiedPermissionCode.CanMakeShorexRequest) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
      case ReservationAction.OpenInventoryFromVoyage:
        return this.hasPermission(CodifiedPermissionCode.OpenInventoryFromVoyage) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
    }

    return ReservationPermission.UnknownAction;
  }

  /**
   * Check if this action is danied
   *
   * @param {ReservationAction} action
   * @return {*}  {boolean}
   * @memberof ReservationPermission
   */
  isDenied(action: ReservationAction): IReservationPermissionResponse {

    switch (action) {
      case ReservationAction.CanNotSeeCommission:
        return this.hasDenyPermission(CodifiedPermissionCode.CanNotSeeCommission) ? ReservationPermission.Deny("action denied") : ReservationPermission.Allowed;
    }

    return ReservationPermission.Allowed;
  }
  /**
   * Check permission for booking actions
   *
   * @param {ReservationActionBooking} action
   * @param {ResBooking} [booking] Overwrite current booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  canIOnBooking(action: ReservationActionBooking, booking?: ResBooking): IReservationPermissionResponse {

    if (!booking) {
      booking = this.bookingUtility?.current.getValue();
    }

    if (booking) {
      switch (action) {
        case ReservationActionBooking.AddOptionsBookingVersion:
          // TODO: introduce a permission?
          // if (!this.hasPermission(CodifiedPermissionCode.AddOptionsBookingVersion)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.DeleteOptionsBookingVersion:
          // TODO: introduce a permission?
          // if (!this.hasPermission(CodifiedPermissionCode.DeleteOptionsBookingVersion)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.ManageGuestBookingVersion:
          // TODO: introduce a permission?
          // if (!this.hasPermission(CodifiedPermissionCode.ManageGuestBookingVersion)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.OpenGuestInCRM:
          // TODO: introduce a permission?
          // RF 06/10/2023: Richiesto da Anna di escludere il link al CRM dal controllo degli utenti readonly
          if (!booking.isValid()) return ReservationPermission.Deny('Invalid booking');
          if (booking.hasErrors()) return ReservationPermission.Deny('Booking has errors');
          if (booking.isEmpty()) return ReservationPermission.Deny('booking is empty');
          return ReservationPermission.Allowed;
        case ReservationActionBooking.ManageGuestPreferencesBooking:
          // TODO: introduce a permission?
          // if (!this.hasPermission(CodifiedPermissionCode.ManageGuestPreferencesBookingVersion)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.ManageGuestInsuranceBooking:
          // TODO: introduce a permission?
          // if (!this.hasPermission(CodifiedPermissionCode.ManageGuestPreferencesBookingVersion)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);

        case ReservationActionBooking.CloneBooking:
          // TODO: introduce a permission?
          // if (!this.hasPermission(CodifiedPermissionCode.DeleteOptionsBookingVersion)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);

        case ReservationActionBooking.ChangeSuiteBookingVersion:
          // TODO: introduce a permission?
          if(!booking.changeSuite) {
            return ReservationPermission.Deny('no change suite allow');
          }
          // if (!this.hasPermission(CodifiedPermissionCode.ChangeSuiteBookingVersion)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.UpgradeSuiteBookingVersion:
          if (!this.hasPermission(CodifiedPermissionCode.UpgradeSuiteBookingVersion)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.ChangeVoyageBookingVersion:
          if(booking.multiSuite) {
            return ReservationPermission.Deny('Booking has Multi Suite')
          }
          // TODO: introduce a permission?
          // if (!this.hasPermission(CodifiedPermissionCode.ChangeVoyageBookingVersion)) return ReservationPermission.MissingPermission;
          return this.canIV2VOnBooking(booking);
        case ReservationActionBooking.CloneBookingVersion:
          // TODO: introduce a permission?
          // if (!this.hasPermission(CodifiedPermissionCode.CloneBookingVersion)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnQuote(booking);
        case ReservationActionBooking.ExtendJourneyBooking:
          // TODO: introduce a permission?
          // if (!this.hasPermission(CodifiedPermissionCode.ExtendJourneyBooking)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.EditBookingNotes:
          // TODO: introduce a permission?
          // if (!this.hasPermission(CodifiedPermissionCode.ExtendJourneyBooking)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.ConfirmBooking:
          if (!this.hasPermission(CodifiedPermissionCode.ConfirmBooking)) return ReservationPermission.MissingPermission;
          return this.canIConfirmBooking(booking);
        case ReservationActionBooking.PromoteBooking:
          if (!this.hasPermission(CodifiedPermissionCode.PromoteBooking)) return ReservationPermission.MissingPermission;
          return this.canIPromoteBooking(booking);
        case ReservationActionBooking.DeleteBooking:
          if (!this.hasPermission(CodifiedPermissionCode.DeleteBooking)) return ReservationPermission.MissingPermission;
          return this.canIDeleteBooking(booking);
        case ReservationActionBooking.EditCommissionAmountBooking:
          if (!this.hasPermission(CodifiedPermissionCode.EditCommissionAmount)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.EditCommissionPercBooking:
          if (!this.hasPermission(CodifiedPermissionCode.EditCommissionPerc)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.RecalculateBooking:
          if (!this.hasPermission(CodifiedPermissionCode.RecalculateBooking)) return ReservationPermission.MissingPermission;
          return this.canIRecalcBooking(booking);
        case ReservationActionBooking.EditBooking:
          if (!this.hasPermission(CodifiedPermissionCode.EditBooking)) return ReservationPermission.MissingPermission;
          return this.canIEditBooking(booking);
        case ReservationActionBooking.LinkBooking:
          if (!this.hasPermission(CodifiedPermissionCode.LinkBooking)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.PayWithLinkOnBooking:
          if (!this.hasPermission(CodifiedPermissionCode.PayWithLinkOnBooking)) return ReservationPermission.MissingPermission;
          return this.canIPayOnBooking(booking);
        case ReservationActionBooking.PayOnBooking:
          if (!this.hasPermission(CodifiedPermissionCode.PayOnBooking)) return ReservationPermission.MissingPermission;
          return this.canIPayOnBooking(booking);
        case ReservationActionBooking.TransferCashOnBooking:
          if (!this.hasPermission(CodifiedPermissionCode.TransferCashOnBooking)) return ReservationPermission.MissingPermission;
          return this.canITransferCashBooking(booking);
        case ReservationActionBooking.RefundBooking:
          if (!this.hasPermission(CodifiedPermissionCode.RefundBooking)) return ReservationPermission.MissingPermission;
          return this.canIRefundBooking(booking);
        case ReservationActionBooking.UnrefRefundBooking:
          if (!this.hasPermission(CodifiedPermissionCode.UnrefRefundBooking)) return ReservationPermission.MissingPermission;
          return this.canIRefundBooking(booking);
        case ReservationActionBooking.UnrefRefundBookingNoToken:
          if (!this.hasPermission(CodifiedPermissionCode.UnrefRefundBookingNoToken)) return ReservationPermission.MissingPermission;
          return this.canIRefundBooking(booking);
        case ReservationActionBooking.ManualRefund:
          if (!this.hasPermission(CodifiedPermissionCode.ManualRefund)) return ReservationPermission.MissingPermission;
          return this.canIRefundBooking(booking);
        case ReservationActionBooking.VoidBooking:
          if (!this.hasPermission(CodifiedPermissionCode.VoidBooking)) return ReservationPermission.MissingPermission;
          return this.canIVoidBooking(booking);
        case ReservationActionBooking.RepayBooking:
          if (!this.hasPermission(CodifiedPermissionCode.RepayBooking)) return ReservationPermission.MissingPermission;
          return this.canIRepayBooking(booking);
        case ReservationActionBooking.EditBookingOptions:
          if (!this.hasPermission(CodifiedPermissionCode.EditBookingOptions)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.ConvertOD:
          return this.canIConvertOD(booking);
        case ReservationActionBooking.RefundOD:
          return this.canIRefundOD(booking);
        case ReservationActionBooking.EditGuestBooking:
          if (!this.hasPermission(CodifiedPermissionCode.EditGuestBooking)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.ChangePriceTypeBooking:
          if (!this.hasPermission(CodifiedPermissionCode.ChangePriceTypeBooking)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.EditBookingGuestAir:
          if (!this.hasPermission(CodifiedPermissionCode.EditBookingGuestAir)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.SearchAirFlights:
          if (!this.hasPermission(CodifiedPermissionCode.SearchAirFlights)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.BookAirFlights:
          if (!this.hasPermission(CodifiedPermissionCode.BookAirFlights)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.DeleteAirPnrs:
          if (!this.hasPermission(CodifiedPermissionCode.DeleteAirPnrs)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.DeleteTicketedAirPnrs:
          if (!this.hasPermission(CodifiedPermissionCode.DeleteTicketedAirPnrs)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.RefreshAirPnrs:
          if (!this.hasPermission(CodifiedPermissionCode.RefreshAirPnrs)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.UploadAirPnrs:
          if (!this.hasPermission(CodifiedPermissionCode.UploadAirPnrs)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.ViewImportPnr:
          if (!this.hasPermission(CodifiedPermissionCode.ViewImportPnr)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.ChangePnrAirClass:
          if (!this.hasPermission(CodifiedPermissionCode.ChangePnrAirClass)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.AirDeptAdmin:
          if (!this.hasPermission(CodifiedPermissionCode.AirDeptAdmin)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.EditBookingCruisePrice:
          if (!this.hasPermission(CodifiedPermissionCode.EditBookingCruisePrice)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.EditBookingGuestType:
          if (!this.hasPermission(CodifiedPermissionCode.EditBookingGuestType)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.EditBookingPriceCategory:
          if (!this.hasPermission(CodifiedPermissionCode.EditBookingPriceCategory)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.EditBookingFixedPrice:
          if (!this.hasPermission(CodifiedPermissionCode.EditBookingFixedPrice)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.AcceptBookingSuiteOversize:
          if (!this.hasPermission(CodifiedPermissionCode.AcceptBookingSuiteOversize)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.DeleteOrEditBookingUserNote:
          if (!this.hasPermission(CodifiedPermissionCode.DeleteOrEditBookingUserNote)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.ViewBookingCertificate:
          if (!this.hasPermission(CodifiedPermissionCode.ViewBookingCertificate)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.CreateBookingCertificate:
          if (!this.hasPermission(CodifiedPermissionCode.CreateBookingCertificate)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.EditBookingCertificate:
          if (!this.hasPermission(CodifiedPermissionCode.EditBookingCertificate)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.EditBookingCertificateExpirationDate:
          if (!this.hasPermission(CodifiedPermissionCode.EditBookingCertificateExpirationDate)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.UseBookingCertificate:
          if (!this.hasPermission(CodifiedPermissionCode.UseBookingCertificate)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.SearchBookingCertificate:
          if (!this.hasPermission(CodifiedPermissionCode.SearchBookingCertificate)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.ImportBookingCertificate:
          if (!this.hasPermission(CodifiedPermissionCode.ImportBookingCertificate)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.ActOnBhealfOfGuest:
          if (!this.hasPermission(CodifiedPermissionCode.ActOnBhealfOfGuest)) return ReservationPermission.MissingPermission;
          return ReservationPermission.Allowed; //this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.BookingMyActivities:
          if (!this.hasPermission(CodifiedPermissionCode.BookingMyActivities)) return ReservationPermission.MissingPermission;
          return ReservationPermission.Allowed; //this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.DeleteBookingPackage:
          if (!this.hasPermission(CodifiedPermissionCode.DeleteBookingPackage)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.AddLand:
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.UpgradePackage:
          if (!this.hasPermission(CodifiedPermissionCode.UpgradePackage)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.HandleCustomPackageBooking:
        case ReservationActionBooking.CopyBooking:
          return this.isReadOnly ? ReservationPermission.ReadOnlyUser : ReservationPermission.Allowed;
        case ReservationActionBooking.BookingReferralDiscounts:
          if (!this.hasPermission(CodifiedPermissionCode.BookingReferralDiscounts)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.CanSplitPayer:
          if (!this.hasPermission(CodifiedPermissionCode.CanSplitPayer)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.CanEditBkgItems:
          if (!this.hasPermission(CodifiedPermissionCode.CanEditBkgItems)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.CanDeleteBkgItems:
          if (!this.hasPermission(CodifiedPermissionCode.CanDeleteBkgItems)) return ReservationPermission.MissingPermission;
          return this.canIGenericActOnBooking(booking);
        case ReservationActionBooking.CanOpenBkgExtendVoyageMenu:
          return this.canIGenericActOnBooking(booking);

        case ReservationActionBooking.CanUseOneDocInvoce:
          if(!this.isProductionEnvironment) return ReservationPermission.Allowed

          const invoice = this.oneDocConfig.invoice
          if(Array.isArray(invoice) && !invoice.includes(booking.companyID)) return ReservationPermission.Allowed

          return ReservationPermission.Deny('Not Allowed on this company')

          case ReservationActionBooking.CanUseOneDocProposal:
          if(!this.isProductionEnvironment) return ReservationPermission.Allowed

          const proposal = this.oneDocConfig.proposal
          if(Array.isArray(proposal) && !proposal.includes(booking.companyID)) return ReservationPermission.Allowed

          return ReservationPermission.Deny('Not Allowed on this company')
      }
    }

    return ReservationPermission.UnknownAction;
  }

  /**
   * Check permission for view certificates
   *
   * @param {ResBooking} [booking]
   * @return {IReservationPermissionResponse}
   * @memberof ReservationPermission
   */
  canIOperateOnCertificate(booking?: ResBooking): IReservationPermissionResponse{
    if (!this.hasPermission(CodifiedPermissionCode.ViewBookingCertificate)) return ReservationPermission.MissingPermission;

    if (!booking) {
      booking = this.bookingUtility?.current.getValue();
    }

    if(booking!.certificateNeedPermission){
      if (!this.hasPermission(CodifiedPermissionCode.Certificate_Admin)) return ReservationPermission.MissingPermission;
    }

    return ReservationPermission.Allowed
  }



  /**
   * Check permission for edit booking field
   *
   * @param {ReservationEditActionBooking} action
   * @param {ResBooking} [booking] Overwrite current booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  canIEditOnBooking(action: ReservationEditActionBooking, booking?: ResBooking): IReservationPermissionResponse {

    if (!booking) {
      booking = this.bookingUtility?.current.getValue();
    }

    if (booking) {

      const canEditBooking = this.canIOnBooking(ReservationActionBooking.EditBooking, booking);

      if (canEditBooking.isAllowed) {
        switch (action) {

          case ReservationEditActionBooking.EditBookingName:
            // TODO: introduce a permission?
            // return this.hasPermission(CodifiedPermissionCode.EditBookingName) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
            return ReservationPermission.Allowed;
          case ReservationEditActionBooking.EditBookingConfirmationDate:
            return this.hasPermission(CodifiedPermissionCode.EditBookingConfirmationDate) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
          case ReservationEditActionBooking.EditBookingDepositDate:
            return this.hasPermission(CodifiedPermissionCode.EditBookingDepositDate) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
          case ReservationEditActionBooking.EditBookingFinalPaymentDate:
            return this.hasPermission(CodifiedPermissionCode.EditBookingFinalPaymentDate) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
          case ReservationEditActionBooking.EditBookingCreationDate:
            if (booking.editCreationDate) {
              return this.hasPermission(CodifiedPermissionCode.EditBookingCreationDate) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
            } else {
              return this.hasPermission(CodifiedPermissionCode.EditForceBookingCreationDate) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
            }
          case ReservationEditActionBooking.EditBookingGroup:
            return this.hasPermission(CodifiedPermissionCode.EditBookingGroup) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
          case ReservationEditActionBooking.EditBookingAgentOwner:
            return this.hasPermission(CodifiedPermissionCode.EditBookingAgentOwner) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
          case ReservationEditActionBooking.EditBookingOwner:
            return this.hasPermission(CodifiedPermissionCode.EditBookingOwner) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
          case ReservationEditActionBooking.EditBookingOwnerEmail:
            return this.hasPermission(CodifiedPermissionCode.EditBookingOwnerEmail) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
          case ReservationEditActionBooking.EditBookingPriceType:
            return this.hasPermission(CodifiedPermissionCode.EditBookingPriceType) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
          case ReservationEditActionBooking.EditBookingCreatedBy:
            return this.hasPermission(CodifiedPermissionCode.EditBookingCreatedBy) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
          // TODO: uncomment to edit booking currency
          // case ReservationEditActionBooking.EditBookingCurrency:
          //   return this.hasPermission(CodifiedPermissionCode.EditBookingCurrency) ? ReservationPermission.Allowed : ReservationPermission.Deny("Missing permission");
          default:
            return ReservationPermission.UnknownAction;
        }
      }

      return canEditBooking;
    }

    return ReservationPermission.UnknownAction;
  }
  /**
   * Checks if a permission is included in user's permissions
   *
   * @param {string} permissionCode
   * @return {*}  {boolean}
   * @memberof ReservationPermission
   */
  hasPermission(permissionCode: string): boolean {
    if (this.isAdmin) {
      return true;
    } else {
      const p = this.permission?.filter(p => p?.toLocaleLowerCase() === permissionCode?.toLocaleLowerCase()) ?? [];
      return p.length > 0;
    }
  }
  /**
   * Checks if a permission is included in user's permissions
   *
   * @param {string} permissionCode
   * @return {*}  {boolean}
   * @memberof ReservationPermission
   */
  hasDenyPermission(permissionCode: string): boolean {
    if (this.isAdmin) {
      return false;
    } else {
      const p = this.permission?.filter(p => p?.toLocaleLowerCase() === permissionCode?.toLocaleLowerCase()) ?? [];
      return p.length > 0;
    }
  }
  /**
   * Modificata x non utilizzare il flag IsAdmin
   * ( la utilizzo in eidos-button-list)
   *
   * @param {string} permissionCode
   * @return {*}  {boolean}
   * @memberof ReservationPermission
   */
  hasPermissionNoAdmin(permissionCode: string): boolean {
    const p = this.permission?.filter(p => p?.toLocaleLowerCase() === permissionCode?.toLocaleLowerCase()) ?? [];
    return p.length > 0;
  }
  /**
   * Check if bkg is locked by the user by looking in bookingUtility?.bookingLock subject
   *
   * @private
   * @param {ResBooking} bkg
   * @return {*}  {boolean}
   * @memberof ReservationPermission
   */
  private isBookingLocked(bkg: ResBooking): boolean {
    return this.bookingUtility?.isCurrentBkgLocked(bkg) ?? false;
  }
  /**
   * Check if bkg is R/O by looking in bookingUtility?.bookingLock subject
   *
   * @private
   * @param {ResBooking} bkg
   * @return {*}  {boolean}
   * @memberof ReservationPermission
   */
  private isBookingReadOnly(bkg: ResBooking): boolean {
    return this.bookingUtility?.isCurrentBkgReadOnly(bkg) ?? false;
  }
  /**
   * Checks if a booking has valid conditions for generic work on it
   *
   * @private
   * @param {ResBooking} booking
   * @param {boolean} [skipReadOnlyUserCheck=false] Skip R/O users check
   * @return {*}  {IReservationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIGenericActOnBooking(booking: ResBooking, skipReadOnlyUserCheck: boolean = false): IReservationPermissionResponse {
    if (!booking.isValid()) return ReservationPermission.Deny('Invalid booking');
    // if (booking.isDeleted()) return ReservationPermission.Deny('booking is deleted');
    if (booking.hasErrors()) return ReservationPermission.Deny('Booking has errors');
    if (booking.isEmpty()) return ReservationPermission.Deny('booking is empty');
    if (!skipReadOnlyUserCheck) {
      if (this.isReadOnly) return ReservationPermission.ReadOnlyUser;
    }
    if (!this.isBookingLocked(booking)) return ReservationPermission.Deny('Booking is not locked');
    if (this.isBookingReadOnly(booking) && !this.hasPermission(CodifiedPermissionCode.ActOnReadOnlyBooking)) return ReservationPermission.Deny('Booking is readonly');
    if (booking.allVoyageSailed && !this.hasPermission(CodifiedPermissionCode.CanEditBookingSailed)) return ReservationPermission.Deny('All Voyages are already sailed');

    return ReservationPermission.Allowed;
  }
  /**
   * Checks if a booking OD has valid conditions for generic work on it
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIGenericActOnOD(booking: ResBooking): IReservationPermissionResponse {

    const canIGenericActOnBooking = this.canIGenericActOnBooking(booking);
    if (canIGenericActOnBooking.isAllowed) {

      if (!booking.isOpenDeposit) return ReservationPermission.Deny('Booking is not OD');

      //all it's ok
      return ReservationPermission.Allowed;
    }
    return canIGenericActOnBooking;
  }
  /**
   * Checks if a quote has valid conditions for generic work on it
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIGenericActOnQuote(booking: ResBooking): IReservationPermissionResponse {

    const canIGenericActOnBooking = this.canIGenericActOnBooking(booking);
    if (canIGenericActOnBooking.isAllowed) {

      if (!booking.isQuote()) return ReservationPermission.Deny('Invalid status');

      //all it's ok
      return ReservationPermission.Allowed;
    }
    return canIGenericActOnBooking;
  }
  /**
   * Checks if a booking has valid conditions for confirmation
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIConfirmBooking(booking: ResBooking): IReservationPermissionResponse {

    const canIGenericActOnBooking = this.canIGenericActOnBooking(booking);
    if (canIGenericActOnBooking.isAllowed) {

      if (!booking.isOption()) return ReservationPermission.Deny('Invalid status');

      //all it's ok
      return ReservationPermission.Allowed;
    }
    return canIGenericActOnBooking;
  }
  /**
   * Checks if a booking has valid conditions for edit it
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIEditBooking(booking: ResBooking): IReservationPermissionResponse {

    const canIGenericActOnBooking = this.canIGenericActOnBooking(booking);
    if (canIGenericActOnBooking.isAllowed) {

      // No specific conditions for edit a booking...

      //all it's ok
      return ReservationPermission.Allowed;
    }
    return canIGenericActOnBooking;
  }
  /**
   * Checks if a booking has valid conditions for promotion
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIPromoteBooking(booking: ResBooking): IReservationPermissionResponse {

    // Booking must be a quote!
    const canIGenericActOnQuote = this.canIGenericActOnQuote(booking);
    if (canIGenericActOnQuote.isAllowed) {

      // No specific conditions for promote a quote...

      //all it's ok
      return ReservationPermission.Allowed;
    }
    return canIGenericActOnQuote;
  }
  /**
   * Checks if a booking has valid conditions for deletion
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIDeleteBooking(booking: ResBooking): IReservationPermissionResponse {
    const canIGenericActOnBooking = this.canIGenericActOnBooking(booking);
    if (canIGenericActOnBooking.isAllowed) {

      // No specific conditions for delete a booking...

      //all it's ok
      return ReservationPermission.Allowed;
    }
    return canIGenericActOnBooking;
  }
  /**
   * Checks if a booking has valid conditions for recalculation
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIRecalcBooking(booking: ResBooking): IReservationPermissionResponse {
    const canIGenericActOnBooking = this.canIGenericActOnBooking(booking);
    if (canIGenericActOnBooking.isAllowed) {

      // No specific conditions for recalc a booking...

      //all it's ok
      return ReservationPermission.Allowed;
    }
    return canIGenericActOnBooking;
  }

  /**
   * Checks if a booking has valid conditions for payment handling
   * Common validation for: pay, refund, void
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canHandleBookingPayment(booking: ResBooking): IReservationPermissionResponse {
    const canIGenericActOnBooking = this.canIGenericActOnBooking(booking);
    if (canIGenericActOnBooking.isAllowed) {

      if (!(booking.isOption() || booking.isBooked())) return ReservationPermission.Deny('Invalid status');

      //all it's ok
      return ReservationPermission.Allowed;
    }
    return canIGenericActOnBooking;
  }
  /**
   * Checks if a booking has valid conditions for transfer cash
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canITransferCashBooking(booking: ResBooking): IReservationPermissionResponse {

    const canIGenericActOnBooking = this.canIGenericActOnBooking(booking);
    if (canIGenericActOnBooking.isAllowed) {

      // if (!(booking.isOption() || booking.isBooked())) return ReservationPermission.Deny('Invalid status');
      if (!booking.isAlreadyPayed()) return ReservationPermission.Deny('Booking has not payed amount');

      //all it's ok
      return ReservationPermission.Allowed;
    }
    return canIGenericActOnBooking;
  }
  /**
   * Checks if a booking has valid conditions to insert a payment
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIPayOnBooking(booking: ResBooking): IReservationPermissionResponse {
    const canHandleBookingPayment = this.canHandleBookingPayment(booking);
    if (canHandleBookingPayment.isAllowed) {

      // 12.09.2022 Condition removed due to Alberto's advice
      // if (booking.isFullPayed()) return ReservationPermission.Deny('Booking has been already full payed');

      //all it's ok
      return ReservationPermission.Allowed;
    }
    return canHandleBookingPayment;
  }
  /**
   * Checks if a booking has valid conditions to payment refunding
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIRefundBooking(booking: ResBooking): IReservationPermissionResponse {

    if (!booking.isValid()) return ReservationPermission.Deny('Invalid booking');
    if (booking.hasErrors()) return ReservationPermission.Deny('Booking has errors');
    if (booking.isEmpty()) return ReservationPermission.Deny('booking is empty');
    if (!this.isBookingLocked(booking)) return ReservationPermission.Deny('Booking is not locked');

    //all it's ok
    return ReservationPermission.Allowed;
  }
  /**
   * Checks if a booking has valid conditions to void a payment
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIVoidBooking(booking: ResBooking): IReservationPermissionResponse {
    const canHandleBookingPayment = this.canHandleBookingPayment(booking);
    if (canHandleBookingPayment.isAllowed) {

      //all it's ok
      return ReservationPermission.Allowed;
    }

    return canHandleBookingPayment;
  }
  /**
   * Checks if a booking has valid conditions to repay a payment
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIRepayBooking(booking: ResBooking): IReservationPermissionResponse {
    const canHandleBookingPayment = this.canHandleBookingPayment(booking);
    if (canHandleBookingPayment.isAllowed) {

      //all it's ok
      return ReservationPermission.Allowed;
    }

    return canHandleBookingPayment;
  }
  /**
   * Checks if an Open Deposit has valid conditions for conversion in booking
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIConvertOD(booking: ResBooking): IReservationPermissionResponse {
    const canIGenericActOnOD = this.canIGenericActOnOD(booking);
    if (canIGenericActOnOD.isAllowed) {

      //all it's ok
      return ReservationPermission.Allowed;
    }

    return canIGenericActOnOD;
  }
  /**
   * Checks if an Open Deposit has valid conditions for Rerund
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IResevationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIRefundOD(booking: ResBooking): IReservationPermissionResponse {
    const canIGenericActOnOD = this.canIGenericActOnOD(booking);
    if (canIGenericActOnOD.isAllowed) {

      if (!booking.paidInFull) return ReservationPermission.Deny('Open Deposit is not full paid');

      //all it's ok
      return ReservationPermission.Allowed;
    }

    return canIGenericActOnOD;
  }
  /**
   * Checks if a booking has valid conditions to V2V
   *
   * @private
   * @param {ResBooking} booking
   * @return {*}  {IReservationPermissionResponse}
   * @memberof ReservationPermission
   */
  private canIV2VOnBooking(booking: ResBooking): IReservationPermissionResponse {
    if (!booking.isValid()) return ReservationPermission.Deny('Invalid booking');
    if (booking.hasErrors()) return ReservationPermission.Deny('Booking has errors');
    if (booking.isEmpty()) return ReservationPermission.Deny('booking is empty');
    if (this.isReadOnly) return ReservationPermission.ReadOnlyUser;
    if (!this.isBookingLocked(booking)) return ReservationPermission.Deny('Booking is not locked');
    if (this.isBookingReadOnly(booking) && !this.hasPermission(CodifiedPermissionCode.V2VOnDeletedBooking)) return ReservationPermission.Deny('Booking is readonly');
    if (booking.hasPNRs) return ReservationPermission.Deny('Cannot change a voyage with booked PNRs');

    return ReservationPermission.Allowed;
  }
}

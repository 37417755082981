import { SafeBaseObject } from "@app/core/models/base-object.models";
import { DateTime } from "luxon";

export interface IResApiPeopleParameters {
  PackageID: number,
  PageNum: number,
  PageSize: number
  PackagePeopleTypeCod: string,
}

export interface IResApiPackagePeopleManageParameters {
  PackagePeopleID?: number;
  PackageID:number;
  PeopleID:number;
  Status: string;
}
export interface IResApiPeopleManageParameters {
  PackageID?:number;
  PeopleID?:number;
  Title?:string;
  FirstName?:string;
  MiddleName?:string;
  LastName?:string;
  Gender?:string;
  Nationality?:string;
  BirthDate?:Date;
  BirthPlace?:Date;
  BirthNation?:string;
  Age?:number;
  PassportIssuePlace?:string;
  PassportNumber?:string;
  PassportIssueDate?:Date;
  PassportExpireDate?:Date;
  PassportIssueCountryCod?:string;
  Language?:string;
  Occupation?:string;
  Email?:string;
  Phone?:string;
  PhonePrefix?:string;
  Address1?:string;
  Address2?:string;
  Address3?:string;
  Address4?:string;
  City?:string;
  Locality?:string;
  State?:string;
  County?:string;
  Zip1?:string;
  Zip2?:string;
  CountryCod?:string;
  Country?:string;
  HouseHold_Id?:number;
  DestinationID?:number;
  IsTestimonial?:number;
  Status?: string;
  PeopleTypeID?: number;
  PeopleTypeDesc?: string;
  PeopleDesc?: string;
  Bio?: string;
  PackageTravelDateCod?: string;
}
export class ResPeople extends SafeBaseObject {
  peopleID = 0
  packagePeopleID = 0
  salutation = ''
  firstName = ''
  middleName = ''
  lastName = ''
  gender = ''
  nationality = ''
  birthDate = DateTime.invalid('empty')
  birthPlace = ''
  birthNation = ''
  age = 0
  passportIssuePlace = ''
  passportNumber = ''
  passportIssueDate = DateTime.invalid('empty')
  passportExpireDate = DateTime.invalid('empty')
  passportIssueCountry = ''
  language = ''
  occupation = ''
  email = ''
  phone = ''
  address1 = ''
  address2 = ''
  address3 = ''
  address4 = ''
  city = ''
  locality = ''
  state = ''
  county = ''
  zip = ''
  zip2 = ''
  country = ''
  houseHold_Id = 0
  sourceType = ''
  status = ''
  isTestimonial = false
  fullName = ''
  peopleTypeID = 0
  peopleType = ''
  peopleTypeDesc = ''
  peopleDesc = ''
  bio = ''

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);
  }

}

export class ResPeopleType extends SafeBaseObject {
  peopleTypeID = 0
  peopleType = ''

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);
  }
}
import '@angular/common/locales/global/it';
import '@angular/common/locales/global/en';

// Modules
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from '@app/transloco-root.module';
import { NgChartsModule } from 'ng2-charts';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DevExpressModule } from '@app/devexpress.module';

// Pipes
import { CoreTemplateCastPipe } from '@common/pipes/core-template-cast.pipe';
import { CoreSafeHtmlPipe } from '@app/standalones/core-safe-html.pipe';
import { CoreTemplateCastIterablePipe } from '@common/pipes/core-template-iterable-cast.pipe';
import { CoreCallbackPipe } from '@common/pipes/core-callback.pipe';
import { CoreFormatCurrencyPipe, CoreFormatDatePipe, CoreFormatDateTimePipe } from './pipes/core-format.pipe';
import { ViewDisplayFilterPipe } from './pipes/view-display-filter.pipe';
import { CoreMapPipe } from './pipes/core-map.pipe';
import { CoreSortPipe } from './pipes/core-sort.pipe';

// Directives
import { NgTemplateIdentifierDirective } from '@common/directives/eidos-ng-template-identifier.directive';
import { EidosCapsLockDirective } from '@common/directives/eidos-caps-lock.directive';

// Custom adapters and handlers
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { CoreErrorHandler } from '@common/core-error-handler.class';
import { EidosGridHtmlTemplateComponent } from './components/eidos-grid/eidos-grid-html-template/eidos-grid-html-template.component';

////////////////// Components

//// Dialogs
import { EidosChangePasswordDialogComponent } from '@common/components/eidos-dialogs/eidos-change-password-dialog/eidos-change-password-dialog.component';
import { EidosSettingsDialogComponent } from '@common/components/eidos-dialogs/eidos-settings-dialog/eidos-settings-dialog.component';
import { EidosImpersonateUserDialogComponent } from '@common/components/eidos-dialogs/eidos-impersonate-user-dialog/eidos-impersonate-user-dialog.component';
import { EidosDebugDialogComponent } from '@common/components/eidos-dialogs/eidos-debug-dialog/eidos-debug-dialog.component';
import { EidosAlertDialogComponent } from '@common/components/eidos-dialogs/eidos-alert-dialog/eidos-alert-dialog.component';
import { EidosResetCredentialsDialogComponent } from '@common/components/eidos-dialogs/eidos-reset-credentials-dialog/eidos-reset-credentials-dialog.component';
import { EidosErrorDialogComponent } from '@common/components/dialogs/eidos-error-dialog/eidos-error-dialog.component';
import { EidosConfirmationDialogComponent } from './components/eidos-dialogs/eidos-confirmation-dialog/eidos-confirmation-dialog.component';

//// Inputs
import { EidosInputDateTimeComponent } from '@common/components/entities/inputs/eidos-input-datetime/eidos-input-datetime.component';
import { EidosInputBooleanComponent } from '@common/components/entities/inputs/eidos-input-boolean/eidos-input-boolean.component';
import { EidosInputNumberComponent } from '@common/components/entities/inputs/eidos-input-number/eidos-input-number.component';
import { EidosInputCheckboxComponent } from '@common/components/entities/inputs/eidos-input-checkbox/eidos-input-checkbox.component';
import { EidosInputSwitchComponent } from '@common/components/entities/inputs/eidos-input-switch/eidos-input-switch.component';
import { EidosInputSliderComponent } from '@common/components/entities/inputs/eidos-input-slider/eidos-input-slider.component';
import { EidosInputSliderRangeComponent } from '@common/components/entities/inputs/eidos-input-sliderrange/eidos-input-sliderrange.component';
import { EidosInputSelectizeComponent } from '@common/components/entities/inputs/eidos-input-selectize/eidos-input-selectize.component';
import { EidosInputDateTimeRangeComponent } from './components/entities/inputs/eidos-input-datetimerange/eidos-input-datetimerange.component';
import { EidosInputNumberPopupComponent } from './components/entities/inputs/eidos-input-number-popup/eidos-input-number-popup.component';
import { EidosInputPhoneComponent } from './components/entities/inputs/eidos-input-phone/eidos-input-phone.component';
import { EidosUploadFileComponent } from './components/entities/inputs/eidos-upload-file/eidos-upload-file.component';
import { EidosInputEditorComponent } from './components/entities/inputs/eidos-input-editor/eidos-input-editor.component';

//// Filters
import { EidosFilterFormComponent } from '@common/components/eidos-filter-form/eidos-filter-form.component';
import { EidosFilterFormCommandsComponent } from '@common/components/eidos-filter-form/eidos-filter-form-commands/eidos-filter-form-commands.component';
import { EidosFilterFormShowMoreComponent } from '@common/components/eidos-filter-form/eidos-filter-form-show-more/eidos-filter-form-show-more.component';
import { EidosFilterComponent } from '@common/components/entities/filters/eidos-filter/eidos-filter.component';
import { EidosFilterDaterangeComponent } from '@common/components/entities/filters/eidos-filter-daterange/eidos-filter-daterange.component';
import { EidosFilterSliderRangeComponent } from '@common/components/entities/filters/eidos-filter-sliderrange/eidos-filter-sliderrange.component';
import { EidosFilterCheckboxComponent } from '@common/components/entities/filters/eidos-filter-checkbox/eidos-filter-checkbox.component';
import { EidosFilterSwitchComponent } from '@common/components/entities/filters/eidos-filter-switch/eidos-filter-switch.component';
import { EidosFilterSliderComponent } from '@common/components/entities/filters/eidos-filter-slider/eidos-filter-slider.component';
import { EidosFilterSelectizeComponent } from '@common/components/entities/filters/eidos-filter-selectize/eidos-filter-selectize.component';
import { EidosFilterTextComponent } from './components/entities/filters/eidos-filter-text/eidos-filter-text.component';
import { EidosFilterNumberPopupComponent } from './components/entities/filters/eidos-filter-number-popup/eidos-filter-number-popup.component';
import { EidosFilterNumberComponent } from './components/entities/filters/eidos-filter-number/eidos-filter-number.component';
import { EidosFilterDateTimeComponent } from './components/entities/filters/eidos-filter-datetime/eidos-filter-datetime.component';

//// Charts
import { LevelChartComponent } from '@common/components/entities/charts/level-chart/level-chart.component';
import { GsGaugeComponent } from '@common/components/entities/charts/gs-gauge/gs-gauge.component';
import { ChartJsComponent } from '@common/components/entities/charts/chart-js/chart-js.component';

//// Pages
import { EidosMainPageComponent } from '@common/pages/eidos-main-page/eidos-main-page.component';
import { EidosLoginComponent } from '@common/pages/eidos-login/eidos-login.component';
import { EidosResetCredentialsComponent } from '@common/pages/eidos-reset-credentials/eidos-reset-credentials.component';
import { EidosHomePageComponent } from '@common/pages/eidos-home-page/eidos-home-page.component';

import { EidosMenuComponent } from '@common/components/eidos-menu/eidos-menu.component';
import { EidosMenuItemComponent } from '@common/components/eidos-menu-item/eidos-menu-item.component';
import { EidosNotificationsViewerComponent } from '@common/components/eidos-notifications-viewer/eidos-notifications-viewer.component';
import { EidosTopbarComponent } from '@common/components/eidos-topbar/eidos-topbar.component';
import { EidosIconComponent } from '@common/components/eidos-icon/eidos-icon.component';
import { EidosProgressSpinnerComponent } from '@common/components/eidos-progress-spinner/eidos-progress-spinner.component';
import { EidosUserComponent } from '@common/components/eidos-user/eidos-user.component';
import { EidosUserAvatarComponent } from '@common/components/eidos-user-avatar/eidos-user-avatar.component';
import { EidosActionMenuComponent } from '@common/components/eidos-action-menu/eidos-action-menu.component';
import { EidosConfigurationViewerComponent } from '@common/pages/eidos-configuration-viewer/eidos-configuration-viewer.component';
import { EidosConfigurationItemViewerComponent } from '@common/pages/eidos-configuration-viewer/eidos-configuration-item-viewer/eidos-configuration-item-viewer.component';

import { EidosLoginTokenComponent } from '@common/pages/eidos-login-token/eidos-login-token.component';
import { EidosExternalAppContainerComponent } from '@common/eidos-external-app/eidos-external-app-container.component';
import { EidosMatrixComponent } from '@common/components/eidos-matrix/eidos-matrix.component';
import { EidosGridComponent } from '@common/components/eidos-grid/eidos-grid.component';
import { EidosSearchComponent } from '@common/components/eidos-search/eidos-search.component';
import { EidosSearchTabComponent } from '@common/components/eidos-search/eidos-search-tab/eidos-search-tab.component';
import { EidosSearchResultsComponent } from '@common/components/eidos-search/eidos-search-results/eidos-search-results.component';
import { EidosGenericSearchResultsComponent } from '@common/components/eidos-search/eidos-search-results/eidos-generic-search-results/eidos-generic-search-results.component';
import { EidosGridButtonCellTemplateComponent } from './components/eidos-grid/eidos-grid-button-cell-template/eidos-grid-button-cell-template.component';
import { EidosGridLinkCellTemplateComponent } from './components/eidos-grid/eidos-grid-link-cell-template/eidos-grid-link-cell-template.component';
import { EidosFilterCategoryComponent } from './components/entities/filters/eidos-filter-category/eidos-filter-category.component';
import { EidosGridDatetimeTemplateComponent } from './components/eidos-grid/eidos-grid-datetime-template/eidos-grid-datetime-template.component';
import { EidosGridNumberTemplateComponent } from './components/eidos-grid/eidos-grid-number-template/eidos-grid-number-template.component';
import { EidosButtonListComponent } from './components/eidos-button-list/eidos-button-list.component';
import { EidosLightFormComponent } from './components/eidos-light-form/eidos-light-form.component';
import { EidosGridFullTextTemplateComponent } from './components/eidos-grid/eidos-grid-full-text-template/eidos-grid-full-text-template.component';
import { EidosSearchSidebarFiltersComponent } from './components/eidos-search/eidos-search-sidebar-filters/eidos-search-sidebar-filters.component';
import { EidosSearchPaginatorComponent } from './components/eidos-search/eidos-search-paginator/eidos-search-paginator.component';
import { EidosGraphicChooserComponent } from './components/entities/objects/eidos-graphic-chooser/eidos-graphic-chooser.component';
import { EidosGridTouchNotesTemplateComponent } from './components/eidos-grid/eidos-grid-touch-notes-template/eidos-grid-touch-notes-template.component';
import { EidosGridTimeTemplateComponent } from './components/eidos-grid/eidos-grid-time-template/eidos-grid-time-template.component';
import { EidosCommandPanelComponent } from './components/entities/objects/eidos-command-panel/eidos-command-panel.component';
import { EidosTooltipDirective } from './directives/eidos-tooltip.directive';
import { EidosDragDropDirective } from './directives/eidos-drag-drop.directive';
import { EidosModalComponent } from './components/eidos-modal/eidos-modal.component';
import { EidosModalTitleComponent } from './components/eidos-modal/eidos-modal-title/eidos-modal-title.component';
import { EidosButtonComponent } from './components/eidos-button/eidos-button.component';
import { EidosDateSelectorGridComponent } from './components/eidos-date-selector-grid/eidos-date-selector-grid.component';
import { EidosTargetValueDirective } from './directives/eidos-target-value.directive';
import { EidosExpandableTextComponent } from './components/eidos-expandable-text/eidos-expandable-text.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CorePopupListSelectorComponent } from './components/core-popup-list-selector/core-popup-list-selector.component';
import { EidosFilterPopupListSelectorComponent } from './components/entities/filters/eidos-filter-popup-list-selector/eidos-filter-popup-list-selector.component';

/**
 * Main core application
 * It includes components and utils to reuse in every custom module
 *
 * @export
 * @class CoreModule
 */
@NgModule({
  declarations: [
    EidosMainPageComponent,
    EidosMenuComponent,
    EidosMenuItemComponent,
    EidosNotificationsViewerComponent,
    EidosTopbarComponent,
    EidosExternalAppContainerComponent,
    EidosLoginComponent,
    EidosResetCredentialsComponent,
    EidosHomePageComponent,
    EidosIconComponent,
    EidosProgressSpinnerComponent,
    EidosUserComponent,
    EidosUserAvatarComponent,
    EidosActionMenuComponent,

    EidosGridComponent,
    EidosMatrixComponent,
    EidosSearchComponent,
    EidosSearchTabComponent,
    EidosSearchSidebarFiltersComponent,
    EidosSearchPaginatorComponent,
    EidosSearchResultsComponent,
    EidosGenericSearchResultsComponent,
    EidosGridButtonCellTemplateComponent,
    EidosGridLinkCellTemplateComponent,

    EidosGridHtmlTemplateComponent,

    EidosFilterFormComponent,
    EidosFilterFormCommandsComponent,
    EidosFilterFormShowMoreComponent,
    EidosFilterComponent,
    EidosFilterDaterangeComponent,
    EidosFilterSliderRangeComponent,
    EidosFilterCheckboxComponent,
    EidosFilterSwitchComponent,
    EidosFilterSliderComponent,
    EidosFilterSelectizeComponent,
    EidosFilterNumberComponent,
    EidosFilterTextComponent,
    EidosFilterNumberPopupComponent,
    EidosFilterDateTimeComponent,
    EidosFilterPopupListSelectorComponent,

    EidosInputDateTimeComponent,
    EidosInputBooleanComponent,
    EidosInputNumberComponent,
    EidosInputCheckboxComponent,
    EidosInputSwitchComponent,
    EidosInputSliderComponent,
    EidosInputSliderRangeComponent,
    EidosInputSelectizeComponent,
    EidosInputDateTimeRangeComponent,
    EidosInputNumberPopupComponent,
    EidosInputPhoneComponent,
    CorePopupListSelectorComponent,
    EidosUploadFileComponent,

    EidosConfigurationViewerComponent,
    EidosConfigurationItemViewerComponent,
    EidosLoginTokenComponent,

    EidosConfirmationDialogComponent,
    EidosDebugDialogComponent,
    EidosAlertDialogComponent,
    EidosErrorDialogComponent,
    EidosChangePasswordDialogComponent,
    EidosSettingsDialogComponent,
    EidosImpersonateUserDialogComponent,
    EidosResetCredentialsDialogComponent,
    EidosFilterCategoryComponent,

    LevelChartComponent,
    GsGaugeComponent,
    ChartJsComponent,

    EidosCapsLockDirective,
    NgTemplateIdentifierDirective,

    CoreTemplateCastPipe,
    CoreTemplateCastIterablePipe,
    CoreCallbackPipe,
    CoreMapPipe,
    CoreFormatCurrencyPipe,
    CoreFormatDateTimePipe,
    CoreFormatDatePipe,
    CoreSortPipe,
    EidosGridDatetimeTemplateComponent,
    EidosButtonListComponent,
    EidosLightFormComponent,
    EidosGridFullTextTemplateComponent,
    ViewDisplayFilterPipe,
    EidosGridNumberTemplateComponent,
    EidosGridTimeTemplateComponent,
    EidosGraphicChooserComponent,
    EidosCommandPanelComponent,
    EidosGridTouchNotesTemplateComponent,
    EidosTooltipDirective,
    EidosDragDropDirective,
    EidosModalComponent,
    EidosModalTitleComponent,
    EidosButtonComponent,
    EidosDateSelectorGridComponent,
    EidosTargetValueDirective,
    CoreSortPipe,
    EidosExpandableTextComponent,
    EidosInputEditorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DevExpressModule,
    HttpClientModule,
    NgChartsModule,
    MatLuxonDateModule,
    TranslocoRootModule,
    CoreSafeHtmlPipe,
    AngularEditorModule
  ],
  exports: [
    EidosMainPageComponent,
    EidosMenuComponent,
    EidosMenuItemComponent,
    EidosNotificationsViewerComponent,
    EidosTopbarComponent,
    EidosExternalAppContainerComponent,
    EidosLoginComponent,
    EidosResetCredentialsComponent,
    EidosHomePageComponent,
    EidosIconComponent,
    EidosProgressSpinnerComponent,
    EidosUserComponent,
    EidosUserAvatarComponent,
    EidosActionMenuComponent,
    EidosButtonListComponent,

    EidosGridComponent,
    EidosGridLinkCellTemplateComponent,
    EidosGridHtmlTemplateComponent,
    EidosGridDatetimeTemplateComponent,
    EidosGridFullTextTemplateComponent,
    EidosMatrixComponent,
    EidosSearchComponent,
    EidosSearchTabComponent,
    EidosSearchResultsComponent,
    EidosGenericSearchResultsComponent,
    EidosLightFormComponent,
    EidosDateSelectorGridComponent,
    EidosInputEditorComponent,

    EidosFilterFormComponent,
    EidosFilterFormCommandsComponent,
    EidosFilterFormShowMoreComponent,
    EidosFilterComponent,
    EidosFilterDaterangeComponent,
    EidosFilterNumberPopupComponent,
    EidosFilterSliderRangeComponent,
    EidosFilterCheckboxComponent,
    EidosFilterSwitchComponent,
    EidosFilterSliderComponent,
    EidosFilterSelectizeComponent,
    EidosFilterDateTimeComponent,

    EidosInputDateTimeComponent,
    EidosInputBooleanComponent,
    EidosInputNumberComponent,
    EidosInputNumberPopupComponent,
    EidosInputCheckboxComponent,
    EidosInputSwitchComponent,
    EidosInputSliderComponent,
    EidosInputSliderRangeComponent,
    EidosInputSelectizeComponent,
    EidosInputDateTimeRangeComponent,
    EidosInputPhoneComponent,
    EidosUploadFileComponent,

    EidosConfirmationDialogComponent,
    EidosDebugDialogComponent,
    EidosAlertDialogComponent,
    EidosErrorDialogComponent,

    LevelChartComponent,
    GsGaugeComponent,
    ChartJsComponent,

    EidosCapsLockDirective,
    NgTemplateIdentifierDirective,

    CoreTemplateCastPipe,
    CoreTemplateCastIterablePipe,
    CoreCallbackPipe,
    CoreMapPipe,
    CoreFormatCurrencyPipe,
    CoreFormatDateTimePipe,
    CoreFormatDatePipe,
    CoreSortPipe,
    ViewDisplayFilterPipe,
    EidosGraphicChooserComponent,
    EidosCommandPanelComponent,
    EidosTooltipDirective,
    EidosDragDropDirective,
    EidosModalComponent,
    EidosModalTitleComponent,
    EidosTargetValueDirective,
    EidosExpandableTextComponent,
    CorePopupListSelectorComponent
  ],
  providers: [{ provide: ErrorHandler, useClass: CoreErrorHandler }],
})
export class CoreModule { }
